import { Box, Divider, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { EmailSharp } from '@mui/icons-material';
import Footer from "../common/Footer";
import Header from "../common/Header";
import logo from './../../img/home.png';
import value from './../../img/values.png';

const useStyles = makeStyles(() => ({
  container: {
    textAlign: 'center',
    minHeight: '100vh'
  },
  innerContainer: {
    paddingLeft: 20,
    paddingRight: 20,
    lineHeight: '26px'
  },
  textHeader: {
    textAlign: 'left',
    fontWeight: 'bold',
    fontFamily: 'sans-serif',
    marginTop: 10,
    marginBottom: 10
  },
  textBody: {
    fontFamily: 'sans-serif',
    lineHeight: '26px',
    textAlign: 'left',
    paddingTop: 10,
    paddingBottom: 10
  }
}));

const AboutUs = () => {
  const { container, innerContainer, textHeader, textBody } = useStyles();
  return (
    <Box className={container}>
      <Header />
      <Box className={innerContainer} >
        <Box style={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-around', margin: 30}}>
          <Box style={{ flex:1 }}>
            <Box component="img" style={{ borderRadius: '50%', backgroundColor: '#281c4e'}}
              sx={{ height: 180, width: 180, maxHeight: { xs: 223}, maxWidth: { xs: 223 }, border: ''}}
              alt="FansChamp" src={logo}
            />
          </Box>
          <Box style={{flex: 3, flexDirection: 'column'}}>
            <Typography variant="h4" align="left" className={textHeader}>About Us</Typography>
            <Typography variant="body1" align="left" className={textBody}>
              We believe sports is a lot more than just following teams. 
              That's why at FansChamp, we created the platform where you can be part of the game using your skills, 
              spirit and determination to win exciting rewards. 
              Our mission is to help you achieve more with your favourite teams. With us, the thrill of watching game is amplified by rewards. 
            </Typography>

            <Typography variant="body1" align="left" className={textBody}>
              FansChamp is India's first fantasy racing application. We are the fastest-growing fantasy sports website, created for sports fanatics!  
            </Typography>
          </Box>
        </Box>
        <Divider variant="inset" />
        <Box style={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-around', margin: 30}}>
          
          <Box style={{flex: 3, flexDirection: 'column'}}>
            <Typography variant="h4" align="left" className={textHeader}>Mission and Values</Typography>
            <Typography variant="body1" align="left" className={textBody}>
              We strive to help you in achieving more with your favourite players and team in a secure and trusted manner. 
              Our mission is to double the thrill of watching the sports with exciting rewards at your fingertips.
            </Typography>
            <Typography variant="body1" align="left" className={textBody}>
              Fairness standard of our games, Secure, Fast paced and Trustworty platform are our core values.
            </Typography>
          </Box>
          <Box style={{ flex:1 }}>
            <Box component="img" style={{ borderRadius: '50%', backgroundColor: '#281c4e'}}
              sx={{ height: 180, width: 180, maxHeight: { xs: 223}, maxWidth: { xs: 223 }, border: ''}}
              alt="FansChamp" src={value}
            />
          </Box>
        </Box>
        <Divider variant="inset" />
        <Box style={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-around', margin: 30}}>
          <Box style={{flex: 3, flexDirection: 'column'}}>
            <Typography variant="h4" align="left" className={textHeader}>Creator</Typography>
            <Typography variant="body1" align="left" className={textBody}>
              FansChamp is owned and operated by FansQ Technology (OPC) Pvt. Ltd. At FansChamp, we constantly improve and add more Service to make it more friendly and rewarding.
              It has resulted in FansChamp as the fastest growing sports application in the country.
            </Typography>
            <Divider variant="inset" />
            <Box style={{display:'flex', flex: 1, flexDirection: 'row'}} >
              <Box style={{ flex: 1, justifyContent: 'flex-end', alignSelf: 'center', textAlign: 'right', marginRight: 5 }}>
                <EmailSharp style={{ padding: '10px', color: '#644EA6', borderRadius: '50%', backgroundColor: '#f7b819', verticalAlign: 'middle'}} />
              </Box>
              <Box style={{flex: 8, justifyContent: 'flex-start', marginLeft: 5}}>
                <Typography variant="body1" align="left" className={textBody} style={{paddingBottom: 0}}>
                  For any complaint or feedback, please right us at 
                </Typography>
                <Typography variant="body1" align="left" className={textBody} style={{fontWeight: 'bold', paddingTop: 0}}>
                  support@fanschamp.com
                </Typography>
              </Box>
            </Box>
          </Box>
          
        </Box>
        <Divider variant="inset" />       
      </Box>
      <Footer />
    </Box>
  );
}

export default AboutUs;