import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Grid, Tab,  Tabs, Typography  } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {  ArrowDropDownCircle  } from '@mui/icons-material';
import React from "react";
import Footer from "../common/Footer";
import Header from "../common/Header";
import { SportsPoints } from "../constants/SportsPoint";
import Cricket from './../../img/cricket/batball.png';
import MotoGp from './../../img/moto/bike.png';
import CarRace from './../../img/f1/car.png';

const useStyles = makeStyles(() => ({
  container: {
    textAlign: 'center',
    minHeight: '100vh'
  },
  innerContainer: {
    marginLeft: 20,
    marginRight: 20
  },
  tabClass: {
    flex: 1,
    background: "linear-gradient(to bottom, #421DF7 0%, #654FA4 100%)"
  },
  tabHeader: {
  },
  tabIndicator: {
    backgroundColor: "#f7b819 !important",
    height: "10px"
  },
  contentHeaderNumber: {
    backgroundColor:"#644ea6"
  },
  expandableHeader: {
    background: "linear-gradient(to bottom, #90D313 0%, #58BD8B 50%, #3DB187 100%)"
  },
  collapsedHeader: {
    background: "linear-gradient(to bottom, #d4bbed 0%, #d4d4d4 100%)"
  },
  textHeader: {
    textAlign: 'left',
    fontWeight: 'bold',
    fontFamily: 'sans-serif',
    marginLeft: 10,
    marginRight: 10
  },
  textBody: {
    fontFamily: 'sans-serif',
    lineHeight: '26px',
    textAlign: 'left'
  }
}));


const PointSystem = () => {
  const { container, innerContainer, tabClass, tabHeader, tabIndicator, contentHeaderNumber, collapsedHeader, expandableHeader, textHeader, textBody } = useStyles();

  const [ tab, setTab ] = React.useState('cricket');
  const [expandable, setExpandable] = React.useState(new Array(SportsPoints[tab].length));
  
  const handleTabChange = (event, newTab) => {
    setTab(newTab);
  };

  const handleExpandable = (id) => {
    let temp = [...expandable];
    temp[id] = !temp[id];
    setExpandable(temp);
  }

  
  const dataRow = (rule, len) => {
    let data = [];
    data.push(
      <Grid item xs={12 - len * 2 + 2} key={0}>
        <Typography variant='body1' className={textHeader} style={{ textAlign: 'left' } }>{rule.rule}</Typography>
      </Grid>
    );

    for(let i = 1; i < len; i++) {
      let val = rule[`g${i}`];
      const styles  = val !== '-' && val < 0 
        ? { textAlign: 'center', backgroundColor: '#F7BB81', paddingLeft: 5, paddingRight: 5 } 
        : { textAlign: 'center' };
      data.push(
        <Grid item xs={2} key={i}>
          <Typography component='span' variant='body1' className={textBody} style={styles}>{val}</Typography>
        </Grid>
      );
    }
    return data;
  }


  return (
    <Box className={container}>
      <Header />
      <Box className={innerContainer}>
        <Box >
          <Tabs variant="fullWidth" value={tab} classes={{indicator: tabIndicator}} onChange={handleTabChange} centered TabIndicatorProps={{className: tabIndicator }} >
            <Tab icon={<Box component="img"  sx={{ height: 30, width: 30}} src={Cricket} />} className={tabClass} value="cricket" label="Cricket" />
            <Tab icon={<Box component="img"  sx={{ height: 30, width: 30}} src={CarRace} />} className={tabClass} value="f1" label="Formula Race" />
            <Tab icon={<Box component="img"  sx={{ height: 30, width: 30}} src={MotoGp} />}  className={tabClass} value="motoGP" label="MotoGPs" />
          </Tabs>
            {SportsPoints[tab].map((rule, key) =>
              <Accordion key={key} onChange={() => handleExpandable(key)} style={{marginTop: 0}}>
                <AccordionSummary expandIcon={<ArrowDropDownCircle style={{ color:"#644ea6"  }}/>} className={expandable[key] ? expandableHeader : collapsedHeader}>
                  <Avatar className={contentHeaderNumber}  style={{ width: 40, height: 40 }} alt={`${key+1}`} src={rule.icon} />
                  <Typography className={textHeader} style={{alignSelf: 'center', marginLeft: 5}}>{rule.name}</Typography>
                </AccordionSummary>
                <AccordionDetails style={{display: 'flex', flexDirection: 'column'}} >
                  <Box style={{display: 'flex', backgroundColor: '#90d313',  borderBottom: "1px solid red"}}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1}}>
                      {rule.header.map((val, id) =>
                        <Grid item xs={id === 0 ? 12 - rule.header.length * 2 + 2 : 2} key={id}>
                          <Typography variant='body1' className={textHeader} style={id === 0 ? { textAlign: 'left' } : { textAlign: 'center' }}>{val}</Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                  {rule.data.map((val, id) =>
                    <Box style={{display: 'flex', backgroundColor: id % 2 === 0 ? '#FFF9F9' : '#FFFFFF', borderBottom: "1px solid red"}} key={id}>
                      <Grid container rowSpacing={1} columnSpacing={{ xs: 1}}>
                        {dataRow(val, rule.header.length)}
                      </Grid>
                    </Box>
                  )}
                </AccordionDetails>
            </Accordion>      
          )}
        </Box>
      </Box>
      <Footer />
    </Box>
  );
}

export default PointSystem;