import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Typography  } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {  ArrowDropDownCircle  } from '@mui/icons-material';
import React from "react";
import Footer from "../common/Footer";
import Header from "../common/Header";
import { FAQ } from "../constants/TermsConstants";

const useStyles = makeStyles(() => ({
  container: {
    textAlign: 'center',
    minHeight: '100vh'
  },
  innerContainer: {
    marginLeft: 40,
    marginRight: 40,
    marginTop: 20,
    marginBottom: 20
  },
  contentHeaderNumber: {
    backgroundColor:"#644ea6",
    color: '#ffffff',
    fontFamily: 'sans-serif',
    marginLeft: 15,
    marginRight: 15,
    fontWeight: 'bold'
  },
  textHeader: {
    textAlign: 'left',
    fontWeight: 'bold',
    fontFamily: 'sans-serif',
    marginLeft: 10,
    marginRight: 10
  },
  textBody: {
    fontFamily: 'sans-serif',
    lineHeight: '26px',
    textAlign: 'left',
    paddingTop: 5,
    paddingBottom: 5
  }
}));

const FaqScreen = () => {
  const { container, innerContainer, contentHeaderNumber, textHeader, textBody } = useStyles();

  const [expandable, setExpandable] = React.useState(new Array(FAQ.length));

  const handleExpandable = (id) => {
    let temp = [...expandable];
    temp[id] = !temp[id];
    setExpandable(temp);
  }
  
  return (
    <Box className={container}>
      <Header />
      <Box className={innerContainer}>
        <Box >
          {FAQ.map((terms, key) =>
            <Accordion expandable={`${terms.isExpandable}`} key={key} onChange={() => handleExpandable(key)} >
              <AccordionSummary expandIcon={<ArrowDropDownCircle style={{ color:"#644ea6"  }}/>} style={{ borderBottom: "1px solid #644ea6" }}>
                <Typography className={textHeader} style={{color: '#644ea6'}}>{terms.name}</Typography>
              </AccordionSummary>
              <AccordionDetails >
                <Box style={{ flex:1, flexDirection: 'column', justifyContent: 'center', alignSelf: 'center' }}>
                  {terms.rules.map((rule, key, arr) =>
                    <Box style={{ display: 'flex', flex:1, flexDirection: 'row' }} key={key}>
                      {arr.length > 1 &&  <Avatar className={contentHeaderNumber}  style={{ width: 18, height: 18,  alignSelf: 'center', fontSize: 12 }}>{`-`}</Avatar>}
                      <Typography className={textBody} >{rule}</Typography>
                    </Box>
                  )}
                </Box>
              </AccordionDetails>
            </Accordion>  
          )}
        </Box>
      </Box>
      <Footer />
    </Box>
  );
}

export default FaqScreen;