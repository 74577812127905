import logo from './../img/logo-icon.png';
import cri_1 from './../img/rule/cri_1.png';
import cri_2 from './../img/rule/cri_2.png';
import cri_4 from './../img/rule/cri_4.png';
import './Main.css';
import Headers from './common/Header';
import Footer from './common/Footer';
import { Box, Divider, Grid, IconButton, Link, List, ListItem, ListItemText, Typography  } from '@mui/material';
import { Close, ChevronRightRounded, WarningRounded,  CheckCircleOutlineTwoTone  } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import AppStores from './common/AppStores';
// import axios from 'axios';
// import { DOWNLOAD_API } from './common/Settings';

const Main = () => {
  // const [phn, setPhn] = useState('');
  const [alert, setAlert] = useState({open: false, status: 200, color: 'green', message: "Applcation link sent Successfully!"});
  const [width, setWidth] = useState(window.innerWidth);

  const handleWindowSizeChange = () =>  setWidth(window.innerWidth);

  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => { window.removeEventListener('resize', handleWindowSizeChange);}
  }, []);

  // const isMobile = width <= 768;

  // const onSubmitPhn = async () => {
  //   setTimeout(() => setAlert({open: false}), 12000);
  //   if(!/^[789]\d{9}$/.test(phn)) {
  //     setAlert({open: true, status: 500, color: 'red', message: "Invalid number! Please try with a valid Phone number."});
  //     return;
  //   }

  //   await axios.post(DOWNLOAD_API, {userNum: phn, OS: isMobile ? 'MOBILE': 'WEB'})
  //     .then((res) => {
  //         setAlert({open: true, status: 200, color: 'green', message: "Application link sent Successfully!"});
  //     }).catch((error) => {
  //         setAlert({open: true, status: 500, color: 'red', message: "Failed to send application link!"});
  //     });
  //   setPhn('');
  // }
 
  // const handleChange = (event) => {
  //   setPhn(event.target.value);
  // };

  return (
    <>
    <Box className="App" id='container'>
      <Headers/>
      <Box>
        <Box style={{background: "linear-gradient(to bottom, #421DF7 0%, #654FA4 100%)"}}>
          {alert.open &&
            <Box style={{display: 'flex', flexDirection: 'row', height: 50, border: `2px solid ${alert.color}`, backgroundColor: 'rgba(0,0,0,0.5)'}} >
              <Box style={{justifyContent: 'flex-start', flex: 2, alignSelf: 'center'}}>
                {alert.status !== 200 
                  ? <WarningRounded style={{color: 'red', verticalAlign: 'middle', marginRight: 10}} /> 
                  : <CheckCircleOutlineTwoTone style={{color: 'green', verticalAlign: 'middle', marginRight: 10}} /> 
                }
                <Typography variant="overline" style={{fontWeight: 700, color: '#FFFFFF',  textAlign: 'left', verticalAlign: 'middle', fontFamily: 'sans-serif'}}>{alert.message}</Typography>
              </Box>
              <Box style={{justifyContent: 'flex-end', alignSelf: 'center'}}>
                <IconButton aria-label="close"  size="small" 
                  onClick={() => { setAlert({open:false}); }} > <Close fontSize="inherit" style={{color: 'white', alignSelf: 'center'}} /> 
                </IconButton>
              </Box>
            </Box>
          }
          {/* <Box style={{ paddingTop: 80, paddingBottom: 80, display: 'flex',flex: 1, flexDirection: 'column', flexWrap: 'wrap'}}> */}
          <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
              <Box component="img" className="AppLogo"
                sx={{ height: 480, width: 480, maxHeight: { xs: 323}, maxWidth: { xs: 323 }, }}
                alt="FansChamp"
                src={logo}
              />
            </Grid>
            {/* <Box style={{flex: 2, padding: 20}}> */}
            <Grid item xs={12} md={8}>
              <Box style={{flex: 1}}>
                <Box className="dwnld">
                  <Box className="mobileInp">
                    <Typography variant="h3" style={{fontWeight: 700, color: '#FFFFFF',  textAlign: 'left', fontFamily: 'sans-serif'}}>It's Fan Time!</Typography>
                    <Typography variant="h5" style={{fontWeight: 700, color: '#FFFFFF',  textAlign: 'left', fontFamily: 'sans-serif',  paddingBottom: '2%'}}>Unleash your star players potential. Build Smarter Teams.</Typography>
                    <Typography variant="h6" style={{color: '#f7b819', fontFamily: 'sans-serif',  textAlign: 'left', paddingBottom: '5%'}}>India's first fantasy racing game</Typography>
                    <AppStores />
                    {/* <Typography variant="body2" style={{color: '#FFFFFF', textAlign: 'left', fontFamily: 'sans-serif', paddingBottom: '5px'}}>Enter your phone no. for App Download Link</Typography>
                    <Grid container>
                      <Grid item>
                        <TextField placeholder=" Enter mobile number" variant="outlined" style={{backgroundColor:'#FFFFFF'}} value={phn} onChange={handleChange} type='tel' autoFocus size='small'
                          InputProps={{ startAdornment: <><InputAdornment position="start">+91</InputAdornment> <Divider orientation="vertical" variant="fullWidth" flexItem /> </>}}
                          onKeyPress={(ev) => { if (ev.key === 'Enter') { ev.preventDefault(); onSubmitPhn();} }}
                        />
                      </Grid>
                      <Grid item alignItems="stretch" style={{ display: "flex" }}>
                        <Button color="secondary" variant="contained" style={{backgroundColor:'#f7b819', textOverflow: 'ellipsis'}} onClick={onSubmitPhn}>Get App Link</Button>
                      </Grid>
                    </Grid> */}
                    <Typography variant="body2" style={{color: '#f7b819', paddingBottom: '5px',  textAlign: 'left', paddingTop: '2%'}}>Get Extra Cash Bonus for Add Cash, Joining Cash Contests and Referral.</Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          {/* </Box> */}
          </Grid>
        </Box>
        
        <Box style={{ paddingTop: 20, paddingBottom: 20}} >
          <Box style={{paddingTop: 10, paddingBottom: 10}}>
            <Typography variant="h4" style={{fontWeight: 'bold', fontFamily: 'sans-serif'}}>Installing <Typography variant="h4" component="span" style={{color: '#644EA6', fontWeight: 'bold', fontFamily: 'sans-serif'}}>FansChamp  </Typography> App</Typography>
            <Box style={{padding: 10, marginLeft: 20}}>
              <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                <ListItem alignItems="flex-start">
                  <ListItemText
                    inset
                    disableTypography
                    primary={<Typography component={'span'} variant="body1" style={{fontWeight: 'bold', textAlign: 'left', fontFamily: 'sans-serif'}}>iOS Users</Typography>}
                    secondary={
                      <List>
                        <ListItem alignItems="flex-start">
                          <ChevronRightRounded style={{ paddingRight: '5px', color: '#644EA6', verticalAlign: 'middle'}} />
                          <Typography
                            sx={{ display: 'inline' }}
                            component="span"
                            variant="body1"
                            color="text.primary"
                            style={{fontFamily: 'sans-serif', lineHeight: '26px'}}
                          >
                            FansChamp App is available on App Store and can be downloaded free on your iOS devices.
                          </Typography>
                        </ListItem>
                      </List>
                    }
                  />
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem alignItems="flex-start">
                  <ListItemText
                    inset
                    disableTypography
                    primary={<Typography component={'span'} variant="body1" style={{fontWeight: 'bold', textAlign: 'left', fontFamily: 'sans-serif'}}>Android Users</Typography>}
                    secondary={
                      <List>
                        <ListItem alignItems="flex-start">
                          <ChevronRightRounded style={{ paddingRight: '5px', color: '#644EA6', verticalAlign: 'middle'}} />
                          <Typography
                            sx={{ display: 'inline' }}
                            component="span"
                            variant="body1"
                            color="text.primary"
                            style={{fontFamily: 'sans-serif', lineHeight: '26px'}}
                          >
                            Open <Link href="/" color="inherit">FansChamp.com</Link> on the browser.
                          </Typography>
                        </ListItem>
                        <ListItem alignItems="flex-start">
                          <ChevronRightRounded style={{ paddingRight: '5px', color: '#644EA6', verticalAlign: 'middle'}} />
                          <Typography
                            sx={{ display: 'inline' }}
                            component="span"
                            variant="body1"
                            color="text.primary"
                            style={{fontFamily: 'sans-serif', lineHeight: '26px'}}
                          >
                            Click “Download for Android” button to download the apk.
                          </Typography>
                        </ListItem>
                        <ListItem alignItems="flex-start">
                          <ChevronRightRounded style={{ paddingRight: '5px', color: '#644EA6', verticalAlign: 'middle'}} />
                          <Typography
                            sx={{ display: 'inline' }}
                            component="span"
                            variant="body1"
                            color="text.primary"
                            style={{fontFamily: 'sans-serif', lineHeight: '26px'}}
                          >
                            Once the apk is downloaded. Click on the Open button, and then Install button.
                          </Typography>
                        </ListItem>
                        <ListItem alignItems="flex-start">
                          <ChevronRightRounded style={{ paddingRight: '5px', color: '#644EA6', verticalAlign: 'middle'}} />
                          <Typography
                            sx={{ display: 'inline' }}
                            component="span"
                            variant="body1"
                            color="text.primary"
                            style={{fontFamily: 'sans-serif', lineHeight: '26px'}}
                          >
                            If prompted Allow Install from unknown source. Our app is 100% safe made for your device.
                          </Typography>
                        </ListItem>
                      </List>
                    }
                  />
                </ListItem>
                <Divider variant="inset" component="li" />
              </List>
            </Box>
          </Box>
        </Box>
        <Box style={{ paddingTop: 20, paddingBottom: 20}} >
          <Box style={{paddingTop: 10, paddingBottom: 10}}>
            <Typography variant="h4" style={{fontWeight: 'bold'}}> Start
              <Typography variant="h4" component="span" style={{color: '#644EA6', fontWeight: 'bold', fontFamily: 'sans-serif'}}> Playing</Typography> & Win exciting
              <Typography variant="h4" component="span" style={{color: '#644EA6', fontWeight: 'bold', fontFamily: 'sans-serif'}}>  Rewards</Typography>
            </Typography>
            <Box style={{padding: 10, marginLeft: 20}}>
             <Grid container spacing={0} >
                <Grid item xs={12} md={4}>
                  <Typography variant="h6" style={{color: '#644EA6', fontWeight: 'bold', marginTop: 20}}>Select a Match</Typography>
                  <Typography sx={{ display: 'inline' }} component="span" variant="body1" color="text.primary" style={{fontFamily: 'sans-serif'}}>Pick an upcoming match you want to play from the upcoming matches list.</Typography>
                  <Box>
                    <Box component="img"
                      sx={{ height: 233, width: 200, maxHeight: { xs: 233}, maxWidth: { xs: 200 }, }}
                      alt="Select Match"
                      src={cri_1}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography variant="h6" style={{color: '#644EA6', fontWeight: 'bold', marginTop: 20}}>Create your Team</Typography>
                  <Typography sx={{ display: 'inline' }} component="span" variant="body1" color="text.primary">Unleash your skill to select players for your team.</Typography>
                  <Box>
                    <Box component="img"
                      sx={{ height: 233, width: 200, maxHeight: { xs: 233}, maxWidth: { xs: 200 }, }}
                      alt="Create Team"
                      src={cri_2}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography variant="h6" style={{color: '#644EA6', fontWeight: 'bold', marginTop: 20}}>Join Contests</Typography>
                  <Typography sx={{ display: 'inline' }} component="span" variant="body1" color="text.primary">Pick up between various contests and win exciting rewards.</Typography>
                  <Box>
                    <Box component="img"
                      sx={{ height: 233, width: 200, maxHeight: { xs: 233}, maxWidth: { xs: 200 }, }}
                      alt="Join Contests"
                      src={cri_4}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
      <Footer />
    </Box>
    </>
  );
}

export default Main;
