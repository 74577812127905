
export const TeamSelection = Object.freeze({
  cricket: {
    teamRule: {
      wkCount: { min: 1, max: 2 },
      batCount: { min: 3, max: 6 },
      bowlCount: { min: 3, max: 6 },
      arCount: { min: 1, max: 4 },
      headerTeams: 2,
      teamPlayerCount: 7,
      totalPoints: 100.00,
      totalPlayer: 11
    }, 
    tabDetails: [
      {ruleName: 'wkCount', shortName: 'WK', name: 'Wicket Keeper', apiRole: 'WICKET_KEEPER'},
      {ruleName: 'batCount', shortName: 'BAT', name: 'Batesman', apiRole: 'BATSMAN'},
      {ruleName: 'arCount', shortName: 'AR', name: 'All Rounder', apiRole: 'ALL_ROUNDER'},
      {ruleName: 'bowlCount', shortName: 'BOWL', name: 'Bowler', apiRole: 'BOWLER'}
    ],

    leaderSelection: {
      header: ['CAPTAIN (2x)', 'VICE CAPTAIN (1.5x)'],
      displayCondition: [{key:'teamName', check: (val) => val!=null}, {key:'teamName', check: (val) => val!=null}],
      buttons: ['C', 'Vc'],
      pattern:  [[1, 1]],
      maxLeaders: 2
    }
  },
  f1: {
    teamRule: {
      goldDvrCount: { min: 1, max: 2 },
      slvrDvrCount: { min: 2, max: 4 },
      goldCtrCount: { min: 1, max: 2 },
      slvrCtrCount: { min: 2, max: 3 },
      totalPoints: 100.00,
      totalPlayer: 8,
      teamPlayerCount: 8,
      headerTeams: 1
    },
    tabDetails: [
      {ruleName: 'goldDvrCount', shortName: 'GDRIV', name: 'Gold Driver', apiRole: 'GOLD_DRIVER'},
      {ruleName: 'slvrDvrCount', shortName: 'SDRIV', name: 'Silver Driver', apiRole: 'SILVER_DRIVER'},
      {ruleName: 'goldCtrCount', shortName: 'GCTR', name: 'Gold Constructor', apiRole: 'GOLD_CONSTRUCTOR'},
      {ruleName: 'slvrCtrCount', shortName: 'SCTR', name: 'Silver Constructor', apiRole: 'SILVER_CONSTRUCTOR'}
    ],
    leaderSelection: {
      header: ['MEGA DRIVER (2x)', 'TURBO DRIVER (1.5x)', 'MEGA CONSTRUCTOR (2x)'],
      displayCondition: [{key:'teamName', check: (val) => val!=null}, {key:'teamName', check: (val) => val!=null}, {key:'teamName', check: (val) => val==null}],
      buttons: ['Md', 'Td', 'Mc'],
      pattern:  [[1, 1, 0], [0, 0, 1]],
      maxLeaders: 2
    }
  },
  motoGP: {
    teamRule: {
      goldDvrCount: { min: 1, max: 2 },
      slvrDvrCount: { min: 2, max: 4 },
      goldCtrCount: { min: 1, max: 2 },
      slvrCtrCount: { min: 2, max: 3 },
      totalPoints: 100.00,
      totalPlayer: 8,
      teamPlayerCount: 8,
      headerTeams: 1
    },
    tabDetails: [
      {ruleName: 'goldDvrCount', shortName: 'GDRIV', name: 'Gold Driver', apiRole: 'GOLD_DRIVER'},
      {ruleName: 'slvrDvrCount', shortName: 'SDRIV', name: 'Silver Driver', apiRole: 'SILVER_DRIVER'},
      {ruleName: 'goldCtrCount', shortName: 'GCTR', name: 'Gold Constructor', apiRole: 'GOLD_CONSTRUCTOR'},
      {ruleName: 'slvrCtrCount', shortName: 'SCTR', name: 'Silver Constructor', apiRole: 'SILVER_CONSTRUCTOR'}
    ],

    leaderSelection: {
      header: ['MEGA DRIVER (2x)', 'TURBO DRIVER (1.5x)', 'MEGA CONSTRUCTOR (1.75x)'],
      displayCondition: [{key:'teamName', check: (val) => val!=null}, {key:'teamName', check: (val) => val!=null}, {key:'teamName', check: (val) => val==null}],
      buttons: ['Md', 'Td', 'Mc'],
      pattern:  [[1, 1, 0], [0, 0, 1]],
      maxLeaders: 2
    }
  }
});
