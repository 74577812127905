import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Grid, Tab, Tabs, Typography  } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {  ArrowDropDownCircle  } from '@mui/icons-material';
import React from "react";
import Footer from "../common/Footer";
import Header from "../common/Header";
import { SportsRule } from "../constants/SportsPoint";
import Cricket from './../../img/cricket/batball.png';
import MotoGp from './../../img/moto/bike.png';
import CarRace from './../../img/f1/car.png';

const useStyles = makeStyles(() => ({
  container: {
    textAlign: 'center',
    minHeight: '100vh'
  },
  innerContainer: {
    marginLeft: 20,
    marginRight: 20
  },
  tabClass: {
    flex: 1,
    background: "linear-gradient(to bottom, #421DF7 0%, #654FA4 100%)"
  },
  tabHeader: {
  },
  tabIndicator: {
    backgroundColor: "#f7b819 !important",
    height: "10px"
  },
  contentHeaderNumber: {
    backgroundColor:"#644ea6",
    color: '#ffffff',
    fontFamily: 'sans-serif',
    marginLeft: 15,
    marginRight: 15,
    fontWeight: 'bold'
  },
  expandableHeader: {
    background: "linear-gradient(to bottom, #90D313 0%, #58BD8B 50%, #3DB187 100%)"
  },
  collapsedHeader: {
    background: "linear-gradient(to bottom, #d4bbed 0%, #d4d4d4 100%)"
  },
  textHeader: {
    textAlign: 'left',
    fontWeight: 'bold',
    fontFamily: 'sans-serif',
    marginLeft: 10,
    marginRight: 10
  },
  textBody: {
    fontFamily: 'sans-serif',
    lineHeight: '26px',
    textAlign: 'left',
    paddingTop: 5,
    paddingBottom: 5
  }
}));

const HowToPlay = () => {
  const { container, innerContainer, tabClass, tabHeader, tabIndicator, contentHeaderNumber, collapsedHeader, expandableHeader, textHeader, textBody } = useStyles();

    const [ tab, setTab ] = React.useState('cricket');
    const [expandable, setExpandable] = React.useState(new Array(SportsRule[tab].rules.length));
  
    const handleTabChange = (event, newTab) => {
      
      // setExpandable(new Array(SportsRule[newTab].rules.length));
      setTab(newTab);
    };

    const handleExpandable = (id) => {
      let temp = [...expandable];
      temp[id] = !temp[id];
      setExpandable(temp);
    }
  

  return (
    <Box className={container}>
      <Header />
      <Box className={innerContainer}>
        <Box >
          <Tabs variant="fullWidth" value={tab} onChange={handleTabChange} centered TabIndicatorProps={{className: tabIndicator}}>
            <Tab icon={<Box component="img"  sx={{ height: 30, width: 30}} src={Cricket} />} className={tabClass} value="cricket" label="Cricket" />
            <Tab icon={<Box component="img"  sx={{ height: 30, width: 30}} src={CarRace} />} className={tabClass} value="f1" label="Formula Race" />
            <Tab icon={<Box component="img"  sx={{ height: 30, width: 30}} src={MotoGp} />}  className={tabClass} value="motoGP" label="MotoGPs" />
          </Tabs>
            {SportsRule[tab].rules.map((rule, key) =>
              <Accordion key={key} onChange={() => handleExpandable(key)} style={{marginTop: 0}}>
                <AccordionSummary expandIcon={<ArrowDropDownCircle style={{ color:"#644ea6"  }}/>} className={expandable[key] ? expandableHeader : collapsedHeader}>
                  <Avatar className={contentHeaderNumber}  style={{ width: 24, height: 24 }}>{key+1}</Avatar>
                  <Typography className={textHeader}>{rule.name}</Typography>
                </AccordionSummary>
                {
                  key%2 === 0 
                  ? <AccordionDetails >
                      <Box style={{ flex:1 }}>
                        <Box component="img"
                          sx={{ height: 320, width: 280, maxHeight: { xs: 320}, maxWidth: { xs: 280 }, }}
                          src={rule.image}
                        />
                      </Box>
                      <Box style={{ flex:1, flexDirection: 'column', justifyContent: 'center', alignSelf: 'center' }}>
                        {rule.descriptions.map((val, id, arr) =>
                          <Box style={{ display: 'flex', flex:1, flexDirection: 'row' }} key={id}>
                            {arr.length > 1 && <Avatar className={contentHeaderNumber}  style={{ width: 24, height: 24, fontWeight: 'normal', alignSelf: 'center', fontSize: 12 }}>{`${key+1}.${id+1}`}</Avatar>}
                            <Typography className={textBody} key={id}  >{val}</Typography>
                          </Box>
                        )}
                         {rule.teamFormation &&
                          <>
                            <Box style={{display: 'flex', backgroundColor: '#90d313', alignItems: 'center', justifyContent: 'center', alignSelf: 'center', borderBottom: "1px solid red"}}>
                              <Grid container rowSpacing={1} columnSpacing={{ xs: 1}}>
                                <Grid item xs={6}>
                                  <Typography variant='body1' className={textHeader} style={{ textAlign: 'left' }}>Type</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                  <Typography variant='body1' className={textHeader} style={{ textAlign: 'center' }}>Minimum Selection</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                  <Typography variant='body1' className={textHeader} style={{ textAlign: 'center' }}>Maximum Selection</Typography>
                                </Grid>
                              </Grid>
                            </Box>
                            {rule.teamFormation.map((row, id) =>
                              <Box style={{display: 'flex', backgroundColor: id % 2 === 0 ? '#FFF9F9' : '#FFFFFF', borderBottom: "1px solid red"}} key={id}>
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1 }}>
                                  <Grid item xs={6}> <Typography variant='body1' className={textHeader} style={{ backgroundColor:  id % 2 === 0 ? '#FFF9F9' : '#FFFFFF', textAlign: 'left', paddingTop: 0, paddingBottom: 0 }}>{row.name}</Typography> </Grid>
                                  <Grid item xs={3}><Typography variant='body1' className={textBody} style={{ backgroundColor:  id % 2 === 0 ? '#FFF9F9' : '#FFFFFF', textAlign: 'center', paddingTop: 0, paddingBottom: 0 }}>{row.min}</Typography></Grid>
                                  <Grid item xs={3}><Typography variant='body1' className={textBody} style={{ backgroundColor:  id % 2 === 0 ? '#FFF9F9' : '#FFFFFF', textAlign: 'center', paddingTop: 0, paddingBottom: 0 }}>{row.max}</Typography></Grid>
                                </Grid>
                              </Box>
                            )}
                          </>
                        }
                      </Box>
                    </AccordionDetails>
                  : <AccordionDetails >
                      <Box style={{ flex:1, flexDirection: 'column', justifyContent: 'center', alignSelf: 'center' }}>
                        {rule.descriptions.map((val, id, arr) =>
                          <Box style={{ display: 'flex', flex:1, flexDirection: 'row' }} key={id}>
                            {arr.length > 1 && <Avatar className={contentHeaderNumber}  style={{ width: 24, height: 24, fontWeight: 'normal', alignSelf: 'center', fontSize: 12 }}>{`${key+1}.${id+1}`}</Avatar>}
                            <Typography className={textBody} key={id}  >{val}</Typography>
                          </Box>
                        )}
                        {rule.teamFormation &&
                          <>
                            <Box style={{display: 'flex', backgroundColor: '#90d313', alignItems: 'center', justifyContent: 'center', alignSelf: 'center', borderBottom: "1px solid red"}}>
                              <Grid container rowSpacing={1} columnSpacing={{ xs: 1}}>
                                <Grid item xs={6}>
                                  <Typography variant='body1' className={textHeader} style={{ textAlign: 'left' }}>Type</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                  <Typography variant='body1' className={textHeader} style={{ textAlign: 'center' }}>Minimum Selection</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                  <Typography variant='body1' className={textHeader} style={{ textAlign: 'center' }}>Maximum Selection</Typography>
                                </Grid>
                              </Grid>
                            </Box>
                            {rule.teamFormation.map((row, id) =>
                              <Box style={{display: 'flex', backgroundColor: id % 2 === 0 ? '#FFF9F9' : '#FFFFFF', borderBottom: "1px solid red"}} key={id}>
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1 }}>
                                  <Grid item xs={6}> <Typography variant='body1' className={textHeader} style={{ backgroundColor:  id % 2 === 0 ? '#FFF9F9' : '#FFFFFF', textAlign: 'left', paddingTop: 0, paddingBottom: 0 }}>{row.name}</Typography> </Grid>
                                  <Grid item xs={3}><Typography variant='body1' className={textBody} style={{ backgroundColor:  id % 2 === 0 ? '#FFF9F9' : '#FFFFFF', textAlign: 'center', paddingTop: 0, paddingBottom: 0 }}>{row.min}</Typography></Grid>
                                  <Grid item xs={3}><Typography variant='body1' className={textBody} style={{ backgroundColor:  id % 2 === 0 ? '#FFF9F9' : '#FFFFFF', textAlign: 'center', paddingTop: 0, paddingBottom: 0 }}>{row.max}</Typography></Grid>
                                </Grid>
                              </Box>
                            )}
                          </>
                        }
                      </Box>
                      <Box style={{ flex:1,  justifyContent: 'center', alignSelf: 'center'  }}>
                        <Box component="img"
                          sx={{ height: 320, width: 280, maxHeight: { xs: 320}, maxWidth: { xs: 280 }, }}
                          src={rule.image}
                        />
                      </Box>
                    </AccordionDetails>

                }
                
            </Accordion>
                
            )}
        </Box>
      </Box>
      <Footer />
    </Box>
  );
}

export default HowToPlay;