import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Main from './components/Main';
import AboutUs from './components/about-us/AboutUs';
import TermsAndConditions from './components/policy/TermsAndConditions';
import PrivacyPolicy from './components/policy/PrivacyPolicy';
import FairPlayPolicy from './components/policy/FairPlayPolicy';
import ReferFriend from './components/policy/ReferFriend';
import HowToPlay from './components/game-rules/HowToPlay';
import PointSystem from './components/game-rules/PointSystem';
import FAQ from './components/policy/FAQ';
import Redirect from './components/common/Redirect';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main />} />
        {/* <Route path={APP_LINK} element={<Main />} /> */}
        <Route path="/about-us"     element={<AboutUs />} />
        <Route path="/terms-of-use" element={<TermsAndConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/fair-play-terms" element={<FairPlayPolicy />} />
        <Route path="/refer-terms" element={<ReferFriend />} />
        <Route path="/how-to-play" element={<HowToPlay />} />
        <Route path="/point-system" element={<PointSystem />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/ios" element={<Redirect os='ios'/>} />
        <Route path="/android" element={<Redirect os='android'/>} />
    </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
