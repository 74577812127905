import { TeamSelection } from "./SportsConstants";
import CRI_RULE1 from './../../img/rule/cri_1.png';
import CRI_RULE2 from './../../img/rule/cri_2.png';
import CRI_RULE3 from './../../img/rule/cri_3.png';
import CRI_RULE4 from './../../img/rule/cri_4.png';
import CRI_RULE5 from './../../img/rule/cri_5.png';
import F1_RULE1 from './../../img/rule/f1_1.png';
import F1_RULE2 from './../../img/rule/f1_2.png';
import F1_RULE3 from './../../img/rule/f1_3.png';
import F1_RULE4 from './../../img/rule/f1_4.png';
import F1_RULE5 from './../../img/rule/f1_5.png';
import MOTOGP_RULE1 from './../../img/rule/motoGP_1.png';
import MOTOGP_RULE2 from './../../img/rule/motoGP_2.png';
import MOTOGP_RULE3 from './../../img/rule/motoGP_3.png';
import MOTOGP_RULE4 from './../../img/rule/motoGP_4.png';
import MOTOGP_RULE5 from './../../img/rule/motoGP_5.png';
import BAT from './../../img/cricket/bat.png';
import BALL from './../../img/cricket/ball.png';
import STUMPS from './../../img/cricket/stumps.png';
import BATBALL from './../../img/cricket/batball.png';
import GLOVES from './../../img/cricket/gloves.png';
import FORMULA_RACE from './../../img/f1/formulaRace.png';
import F1_CAR from './../../img/f1/formulaRaceCar.png';
import BIKE_RACE from './../../img/moto/Bike2.png';


export const SportsRule = Object.freeze({
  tabs: [
    { key: 'cricket', icon: 'cricket', label: 'Cricket' },
    { key: 'f1', icon: 'flag-checkered', label: 'Formula Race' },
    { key: 'motoGP', icon: 'racing-helmet', label: 'Moto GPs' }
  ],
  cricket: {
    rules: [
      {
        index: 0,
        name: 'Select a Match',
        image: CRI_RULE1,
        descriptions : ["Choose an upcoming match you want to play from the upcoming matches list."],
      },
      {
        index: 1,
        name: 'Create your Team',
        image: CRI_RULE2,
        descriptions : [`Create your team by selecting Create team button and select ${TeamSelection.cricket.teamRule.totalPlayer} players `
          + `from the available list of available players for the match.`,
          `There will be max ${TeamSelection.cricket.teamRule.totalPoints} credit points available for creating the team. `,
          `You can maximum pick ${TeamSelection.cricket.teamRule.teamPlayerCount} players from a team.`,
          `You can create maximum 8 teams per match.`,
          `You must follow the player combinations to create the team.`],
        teamFormation: [
          {name: TeamSelection.cricket.tabDetails[0].name, min: TeamSelection.cricket.teamRule.wkCount.min, max: TeamSelection.cricket.teamRule.wkCount.max},
          {name: TeamSelection.cricket.tabDetails[1].name, min: TeamSelection.cricket.teamRule.batCount.min, max: TeamSelection.cricket.teamRule.batCount.max},
          {name: TeamSelection.cricket.tabDetails[2].name, min: TeamSelection.cricket.teamRule.bowlCount.min, max: TeamSelection.cricket.teamRule.bowlCount.max},
          {name: TeamSelection.cricket.tabDetails[3].name, min: TeamSelection.cricket.teamRule.arCount.min, max: TeamSelection.cricket.teamRule.arCount.max},
        ]
      },
      {
        index: 2,
        name: 'Choose your Team Leaders',
        image: CRI_RULE3,
        descriptions : ["Select Captain and Vice-Captain for your team to earn more points, Captain will earn you 2x "
        + "and Vice-captain will earn you 1.5x points of their score in the match."],
      },
      {
        index: 3,
        name: 'Join a Contest',
        image: CRI_RULE4,
        descriptions : ["There are varitey of contests available for you to join. Join the contest to compete with others joined in the same contest."],
      },
      {
        index: 4,
        name: 'Win and Withdraw money',
        image: CRI_RULE5,
        descriptions : ["Once the match is completed, check your result. If you are in winning zone of paid contest then the winning amount will "
        + "be transferred to your player account. Use them to join more contest or withdraw it and treasure your success."],
      }
    ]
  },
  f1: {
    rules: [
      {
        index: 0,
        name: 'Select a Race',
        image: F1_RULE1,
        descriptions : ["Choose an upcoming Race you want to play from the upcoming races."],
      },
      {
        index: 1,
        name: 'Create your Team',
        image: F1_RULE2,
        descriptions : [`Create your team by selecting Create team button and select ${TeamSelection.f1.teamRule.totalPlayer} players and\or team `
          + `from the available list of available players and teams for the race.`,
          `There will be max ${TeamSelection.f1.teamRule.totalPoints} credit points available for creating the team. `,
          `You can create maximum 8 teams per match.`,
          `You must follow the player combinations to create the team.`],
        teamFormation: [
          {name: TeamSelection.f1.tabDetails[0].name, min: TeamSelection.f1.teamRule.goldDvrCount.min, max: TeamSelection.f1.teamRule.goldDvrCount.max},
          {name: TeamSelection.f1.tabDetails[1].name, min: TeamSelection.f1.teamRule.slvrDvrCount.min, max: TeamSelection.f1.teamRule.slvrDvrCount.max},
          {name: TeamSelection.f1.tabDetails[2].name, min: TeamSelection.f1.teamRule.goldCtrCount.min, max: TeamSelection.f1.teamRule.goldCtrCount.max},
          {name: TeamSelection.f1.tabDetails[3].name, min: TeamSelection.f1.teamRule.slvrCtrCount.min, max: TeamSelection.f1.teamRule.slvrCtrCount.max},
        ]
      },
      {
        index: 2,
        name: 'Choose your Team Leaders',
        image: F1_RULE3,
        descriptions : ["Select Gold Driver and Silver Driver Or Mega Team for your race team to earn more points, Gold Driver will earn you 2x "
        + ", Silver Driver will earn you 1.5x and Mega Team will earn you 2x points of their score in the match.",
          "You can either select Gold Driver and Select Driver or Mega Team as you team leader(s)."],
      },
      {
        index: 3,
        name: 'Join a Contest',
        image: F1_RULE4,
        descriptions : ["There are varitey of contests available for you to join. Join the contest to compete with others joined in the same contest."],
      },
      {
        index: 4,
        name: 'Win and Withdraw money',
        image: F1_RULE5,
        descriptions : ["Once the race is completed, check your result. If you are in winning zone of paid contest then the winning amount will "
        + "be transferred to your player account. Use them to join more contest or withdraw it and treasure your success."],
      }
    ]
  },
  motoGP: {
    rules: [
      {
        index: 0,
        name: 'Select a Race',
        image: MOTOGP_RULE1,
        descriptions : ["Choose an upcoming Race you want to play from the upcoming races."],
      },
      {
        index: 1,
        name: 'Create your Team',
        image: MOTOGP_RULE2,
        descriptions : [`Create your team by selecting Create team button and select ${TeamSelection.f1.teamRule.totalPlayer} players and\or team `
          + `from the available list of available players and teams for the race.`,
          `There will be max ${TeamSelection.f1.teamRule.totalPoints} credit points available for creating the team. `,
          `You can create maximum 8 teams per match.`,
          `You must follow the player combinations to create the team.`],
        teamFormation: [
          {name: TeamSelection.f1.tabDetails[0].name, min: TeamSelection.f1.teamRule.goldDvrCount.min, max: TeamSelection.f1.teamRule.goldDvrCount.max},
          {name: TeamSelection.f1.tabDetails[1].name, min: TeamSelection.f1.teamRule.slvrDvrCount.min, max: TeamSelection.f1.teamRule.slvrDvrCount.max},
          {name: TeamSelection.f1.tabDetails[2].name, min: TeamSelection.f1.teamRule.goldCtrCount.min, max: TeamSelection.f1.teamRule.goldCtrCount.max},
          {name: TeamSelection.f1.tabDetails[3].name, min: TeamSelection.f1.teamRule.slvrCtrCount.min, max: TeamSelection.f1.teamRule.slvrCtrCount.max},
        ]
      },
      {
        index: 2,
        name: 'Choose your Team Leaders',
        image: MOTOGP_RULE3,
        descriptions : ["Select Gold Driver and Silver Driver Or Mega Team for your race team to earn more points, Gold Driver will earn you 2x "
        + ", Silver Driver will earn you 1.5x and Mega Team will earn you 2x points of their score in the match.",
          "You can either select Gold Driver and Select Driver or Mega Team as you team leader(s)."],
      },
      {
        index: 3,
        name: 'Join a Contest',
        image: MOTOGP_RULE4,
        descriptions : ["There are varitey of contests available for you to join. Join the contest to compete with others joined in the same contest."],
      },
      {
        index: 4,
        name: 'Win and Withdraw money',
        image: MOTOGP_RULE5,
        descriptions : ["Once the race is completed, check your result. If you are in winning zone of paid contest then the winning amount will "
        + "be transferred to your player account. Use them to join more contest or withdraw it and treasure your success."],
      }
    ]
  }
});

export const SportsPoints = Object.freeze({
  tabs: [
    { key: 'cricket', icon: 'cricket', label: 'Cricket' },
    { key: 'f1', icon: 'flag-checkered', label: 'Formula Race' },
    { key: 'motoGP', icon: 'racing-helmet', label: 'Moto GPs' }
  ],
  cricket:[
    {
      index: 0,
      name: 'Batting',
      icon: BAT,
      isImage: true,
      header: ['Points you Score', 'T20', 'T10', 'ODI', 'Test'], //, 'Test each Day', 'County'],
      data: [
        { 'id': 0, 'rule': 'Each 1 Run',                 'g1': '+1',  'g2': '+1',  'g3': '+1',  'g4': '+0.5', 'g5': '1',  'g6': '0.5' },
        { 'id': 1, 'rule': 'Each boundary',              'g1': '+8',  'g2': '+10', 'g3': '+4',  'g4': '+4',   'g5': '4',  'g6': '4' },
        { 'id': 2, 'rule': 'Each Sixer',                 'g1': '+12', 'g2': '+15', 'g3': '+6',  'g4': '+6',   'g5': '6',  'g6': '6' },
        { 'id': 3, 'rule': 'Duck out (0 runs)',          'g1': '-6',  'g2': '-8',  'g3': '-5',  'g4': '-10',  'g5': '-5', 'g6': '-10' },
        { 'id': 4, 'rule': 'Half Century (50 runs)',     'g1': '+20', 'g2': '+30', 'g3': '+10', 'g4': '10',   'g5': '10', 'g6': '10' },
        { 'id': 5, 'rule': 'Seventy Five (75 runs)',     'g1': '-',   'g2': '+40', 'g3': '-',   'g4': '-',    'g5': '-',  'g6': '-' },
        { 'id': 6, 'rule': 'Century (100 runs)',         'g1': '+30', 'g2': '+70', 'g3': '+15', 'g4': '15',   'g5': '15', 'g6': '15' },
        { 'id': 7, 'rule': 'Hundred Fifty (150 runs)',   'g1': '+40', 'g2': '-',   'g3': '+20', 'g4': '20',   'g5': '20', 'g6': '20' },
        { 'id': 8, 'rule': 'Double Century (200 runs)',  'g1': '+70', 'g2': '-',   'g3': '+50', 'g4': '25',   'g5': '25', 'g6': '25' },
        { 'id': 9, 'rule': 'Tripe Century (300 runs)',   'g1': '-',   'g2': '-',   'g3': '-',   'g4': '50',   'g5': '50', 'g6': '50' }
      ]
    },
    {
      index: 1,
      name: 'Strike Rate',
      icon: BAT,
      isImage: true,
      header: ['Points you Score (Per 100 Run)', 'T20', 'T10', 'ODI', 'Test'],// 'Test Daily', 'County'],
      data: [
        { 'id': 0,  'rule': 'Below 29.99',          'g1': '-20', 'g2': '-20', 'g3': '-15', 'g4': '-',   'g5': '-15', 'g6': '-' },
        { 'id': 1,  'rule': 'Below 39.99',          'g1': '-',   'g2': '-',   'g3': '-',   'g4': '-10', 'g5': '-',   'g6': '-10' },
        { 'id': 2,  'rule': 'Between 30 - 59.99',   'g1': '-15', 'g2': '-15', 'g3': '-12', 'g4': '-',   'g5': '-12', 'g6': '-' },
        { 'id': 3,  'rule': 'Between 40 - 59.99',   'g1': '-',   'g2': '-',   'g3': '-',   'g4': '-5',  'g5': '-',   'g6': '-5' },
        { 'id': 4,  'rule': 'Between 60 - 79.99',   'g1': '-10', 'g2': '-10', 'g3': '-10', 'g4': '+5',  'g5': '-10', 'g6': '+5' },
        { 'id': 5,  'rule': 'Between 80 - 99.99',   'g1': '-5',  'g2': '-5',  'g3': '-5',  'g4': '+10', 'g5': '-5',  'g6': '+10' },
        { 'id': 6,  'rule': 'Above 100',            'g1': '-',   'g2': '-',   'g3': '-',   'g4': '+12', 'g5': '-',   'g6': '-' },
        { 'id': 7,  'rule': 'Between 100 - 129.99', 'g1': '+5',  'g2': '+5',  'g3': '+5',  'g4': '-',   'g5': '+5',  'g6': '+12' },
        { 'id': 8,  'rule': 'Between 130 - 149.99', 'g1': '+10', 'g2': '+10', 'g3': '+10', 'g4': '-',   'g5': '+10', 'g6': '-' },
        { 'id': 9,  'rule': 'Above 150',            'g1': '+15', 'g2': '-',   'g3': '+12', 'g4': '-',   'g5': '-',   'g6': '-' },
        { 'id': 10, 'rule': 'Between 150 - 169.99', 'g1': '-',   'g2': '+15', 'g3': '-',   'g4': '-',   'g5': '+12', 'g6': '-' },
        { 'id': 11, 'rule': 'Above 170',            'g1': '-',   'g2': '+20', 'g3': '-',   'g4': '-',   'g5': '-',   'g6': '-' }
      ]
    },
    {
      index: 2,
      name: 'Bowling',
      icon: BALL,
      isImage: true,
      header: ['Points you Score', 'T20', 'T10', 'ODI', 'Test'],// 'Test Daily', 'County'],
      data: [
        { 'id': 0,  'rule': 'Each Wicket',      'g1': '+30', 'g2': '+40',  'g3': '+25', 'g4': '+20', 'g5': '-15', 'g6': '-' },
        { 'id': 1,  'rule': 'Each Maiden Over', 'g1': '+20', 'g2': '+25',  'g3': '+10', 'g4': '+5',  'g5': '-',   'g6': '-10' },
        { 'id': 2,  'rule': 'Each Noball',      'g1': '-8',  'g2': '-10',  'g3': '-4',  'g4': '-2',  'g5': '-12', 'g6': '-' },
        { 'id': 3,  'rule': 'Each Wide',        'g1': '-4',  'g2': '-6',   'g3': '-2',  'g4': '-1',  'g5': '-',   'g6': '-5' },
        { 'id': 4,  'rule': '2-Wickets haul',   'g1': '-',   'g2': '+6',   'g3': '-',   'g4': '-',   'g5': '-10', 'g6': '+5' },
        { 'id': 5,  'rule': '3-Wickets haul',   'g1': '+6',  'g2': '+12',  'g3': '+3',  'g4': '+5',  'g5': '-5',  'g6': '+10' },
        { 'id': 6,  'rule': '5-Wickets haul',   'g1': '+12', 'g2': '+20',  'g3': '+6',  'g4': '+10', 'g5': '-',   'g6': '-' },
        { 'id': 7,  'rule': '7-Wickets haul',   'g1': '-',   'g2': '-',    'g3': '-',   'g4': '+15', 'g5': '+5',  'g6': '+12' }
      ]
    },
    {
      index: 3,
      name: 'Economy Rate',
      icon: BALL,
      isImage: true,
      header: ['Points you Score (Per Over)', 'T20', 'T10', 'ODI', 'Test'],//, 'Test Daily', 'County'],
      data: [
        { 'id': 0,  'rule': 'Below 2.99',       'g1': '-',    'g2': '-',   'g3': '-',    'g4': '+12',   'g5': '-15', 'g6': '-' },
        { 'id': 1,  'rule': 'Between 3 - 3.99', 'g1': '-',    'g2': '-',   'g3': '-',    'g4': '+10', 'g5': '-',   'g6': '-10' },
        { 'id': 2,  'rule': 'Between 4 - 4.99', 'g1': '-',    'g2': '-',   'g3': '-',    'g4': '+5',   'g5': '-12', 'g6': '-' },
        { 'id': 3,  'rule': 'Between 5 - 5.99', 'g1': '-',    'g2': '-',   'g3': '-',    'g4': '-5',  'g5': '-',   'g6': '-5' },
        { 'id': 4,  'rule': 'Below 5.99',       'g1': '+15',  'g2': '+20', 'g3': '+12',  'g4': '-',  'g5': '-',   'g6': '-5' },
        { 'id': 5,  'rule': 'Between 6 - 6.99', 'g1': '+10',  'g2': '+15', 'g3': '+10',  'g4': '-',  'g5': '-10', 'g6': '+5' },
        { 'id': 6,  'rule': 'Between 7 - 7.99', 'g1': '+5',   'g2': '+10', 'g3': '+5',   'g4': '-',  'g5': '-10', 'g6': '+5' },
        { 'id': 7,  'rule': 'Between 6 - 7.99', 'g1': '-',    'g2': '-',   'g3': '-',    'g4': '-10',  'g5': '-10', 'g6': '+5' },
        { 'id': 8,  'rule': 'Between 8 - 8.99', 'g1': '-5',   'g2': '-10', 'g3': '-5',   'g4': '-', 'g5': '-5',  'g6': '+10' },
        { 'id': 9,  'rule': 'Above 8',          'g1': '-',    'g2': '-',   'g3': '-',    'g4': '-12', 'g5': '-5',  'g6': '+10' },
        { 'id': 10, 'rule': 'Between 9 - 9.99', 'g1': '-10',  'g2': '-15', 'g3': '-10',  'g4': '-', 'g5': '-',   'g6': '-' },
        { 'id': 11, 'rule': 'Above 10',         'g1': '-15',  'g2': '-20', 'g3': '-12',  'g4': '-',   'g5': '+5',  'g6': '+12' }
      ]
    },
    {
      index: 4,
      name: 'Wicket Keeping',
      icon: GLOVES,
      isImage: true,
      header: ['Points you Score', 'T20', 'T10', 'ODI', 'Test'],// 'Test Daily', 'County'],
      data: [
        { 'id': 0,  'rule': 'Each Stumping', 'g1': '+40', 'g2': '+50', 'g3': '+20',  'g4': '+20',  'g5': '-15', 'g6': '-' },
        { 'id': 1,  'rule': 'Each Catch',    'g1': '+30', 'g2': '+35', 'g3': '+15',  'g4': '+10',  'g5': '-',   'g6': '-10' },
        { 'id': 2,  'rule': 'Each Run out',  'g1': '+20', 'g2': '+25', 'g3': '+10',  'g4': '+5',   'g5': '-12', 'g6': '-' },
      ]
    },
    {
      index: 5,
      name: 'Fielding',
      icon: STUMPS,
      isImage: true,
      header: ['Points you Score', 'T20', 'T10', 'ODI', 'Test'],// 'Test Daily', 'County'],
      data: [
        { 'id': 0,  'rule': 'Each Catch',    'g1': '+30', 'g2': '+35', 'g3': '+15',  'g4': '+10',  'g5': '-',   'g6': '-10' },
        { 'id': 1,  'rule': 'Each Run out',  'g1': '+20', 'g2': '+25', 'g3': '+10',  'g4': '+5',   'g5': '-12', 'g6': '-' },
      ]
    },
    {
      index: 6,
      name: 'Miscellaneous',
      icon: BATBALL,
      isImage: true,
      header: ['Points you Score', 'T20', 'T10', 'ODI', 'Test'],// 'Test Daily', 'County'],
      data: [
        { 'id': 0,  'rule': 'Each Player in Playing 11',  'g1': '+6', 'g2': '+2', 'g3': '+4',  'g4': '+8',  'g5': '-',   'g6': '-10' }
      ]
    }
  ],
  f1:[
    {
      index: 0,
      name: 'Qualifying',
      icon: FORMULA_RACE,
      isImage: true,
      header: ['Points you Score', 'Formula 1', 'Formula 2', 'Formula E'],
      data: [
        { 'id': 0, 'rule': 'Pole position',                     'g1': '+20',  'g2': '+20', 'g3': '+20' },
        { 'id': 1, 'rule': '2nd place',                         'g1': '+18',  'g2': '+18', 'g3': '+18' },
        { 'id': 2, 'rule': '3rd place',                         'g1': '+16',  'g2': '+16', 'g3': '+16'},
        { 'id': 3, 'rule': 'Between 4th - 5th place',           'g1': '+15',  'g2': '+15', 'g3': '+15' },
        { 'id': 4, 'rule': 'Between 6th - 8th place',           'g1': '+10',  'g2': '+10', 'g3': '+10' },
        { 'id': 5, 'rule': 'Between 9th - 10th place',          'g1': '+8',   'g2': '+8',  'g3': '+8' },
        { 'id': 6, 'rule': 'Between 11th - 12th place',         'g1': '+5',   'g2': '+5',  'g3': '+5' },
        { 'id': 7, 'rule': 'Between 13th - 15th place',         'g1': '+3',   'g2': '+3',   'g3': '+3' },
        { 'id': 8, 'rule': 'Above 15th',                        'g1': '+1',   'g2': '+1',   'g3': '+1' }
      ]
    },
    {
      index: 1,
      name: 'Sprint Qualifying',
      icon: F1_CAR,
      isImage: true,
      header: ['Points you Score', 'Formula 1', 'Formula 2', 'Formula E'],
      data: [
        { 'id': 0, 'rule': 'Between 1st - 10th place',          'g1': '+15',  'g2': '+15', 'g3': '+15'},
        { 'id': 1, 'rule': 'Between 11th - 15th place',         'g1': '+10',  'g2': '+10', 'g3': '+10' },
        { 'id': 2, 'rule': 'Above 16th',                        'g1': '+5',   'g2': '+5',  'g3': '+5' }
      ]
    },
    {
      index: 2,
      name: 'Sprint Gain & Lost',
      icon: F1_CAR,
      isImage: true,
      header: ['Points you Score', 'Formula 1', 'Formula 2', 'Formula E'],
      data: [
        { 'id': 0, 'rule': 'Each Position Gained (max +25 points)',                  'g1': '+5',  'g2': '+5',  'g3': '+5'},
        { 'id': 1, 'rule': 'Each Position Lost within Top 10 (max -25 points)',      'g1': '-5',  'g2': '-5',  'g3': '-5' },
        { 'id': 2, 'rule': 'Each Position Lost Outside Top 10 (max -15 points)',     'g1': '-3',  'g2': '-3',  'g3': '-3' }
      ]
    },
    {
      index: 3,
      name: 'Race Result',
      icon: FORMULA_RACE,
      isImage: true,
      header: ['Points you Score', 'Formula 1', 'Formula 2', 'Formula E'],
      data: [
        { 'id': 0, 'rule': '1st Place',                    'g1': '+30', 'g2': '+30',  'g3': '+30'},
        { 'id': 1, 'rule': '2nd Plae',                     'g1': '+25', 'g2': '+25',  'g3': '+25' },
        { 'id': 2, 'rule': '3rd Place',                    'g1': '+18', 'g2': '+18',  'g3': '+18' },
        { 'id': 3, 'rule': 'Between 4th - 5th Place',      'g1': '+15', 'g2': '+15',  'g3': '+15' },
        { 'id': 4, 'rule': 'Between 6th - 8th Place',      'g1': '+10', 'g2': '+10',  'g3': '+10' },
        { 'id': 5, 'rule': 'Between 9th - 10th Place',     'g1': '+8',  'g2': '+8',   'g3': '+8' },
        { 'id': 6, 'rule': 'Between 11th - 12th Place',    'g1': '+5',  'g2': '+5',   'g3': '+5' },
        { 'id': 7, 'rule': 'Between 13th - 15th Place',    'g1': '+3',  'g2': '+3',   'g3': '+3' },
        { 'id': 8, 'rule': 'Above 16th',                   'g1': '+1',  'g2': '+1',   'g3': '+1' }
      ]
    },
    ,
    {
      index: 4,
      name: 'Race Laps',
      icon: FORMULA_RACE,
      isImage: true,
      header: ['Points you Score', 'Formula 1', 'Formula 2', 'Formula E'],
      data: [
        { 'id': 0, 'rule': 'Completed 100%',          'g1': '+30', 'g2': '+30',  'g3': '+30'},
        { 'id': 1, 'rule': 'Between 75% - 99.99%',    'g1': '+15', 'g2': '+15',  'g3': '+15' },
        { 'id': 2, 'rule': 'Between 50% - 74.99%',    'g1': '+5',  'g2': '+5',   'g3': '+5' },
        { 'id': 3, 'rule': 'Between 25% - 49.99%',    'g1': '-10', 'g2': '-10',  'g3': '-10' },
        { 'id': 4, 'rule': 'Below 24.99%',            'g1': '-15', 'g2': '-15',  'g3': '-15' }
      ]
    },
    {
      index: 5,
      name: 'Race Gain & Lost',
      icon: FORMULA_RACE,
      isImage: true,
      header: ['Points you Score', 'Formula 1', 'Formula 2', 'Formula E'],
      data: [
        { 'id': 0, 'rule': 'Each Position Gained (max +25 points)',                   'g1': '+5',  'g2': '+5',  'g3': '+5'},
        { 'id': 1, 'rule': 'Beat Teammate (Diver Only)'            ,                  'g1': '+5',  'g2': '+5',  'g3': '+5'},
        { 'id': 2, 'rule': 'Each Position Lost within Top 10 (max -25 points)',       'g1': '-5',  'g2': '-5',  'g3': '-5' },
        { 'id': 3, 'rule': 'Each Position Lost Outside Top 10 (max -15 points)',      'g1': '-3',  'g2': '-3',  'g3': '-3' }
      ]
    },
    {
      index: 6,
      name: 'Miscellaneous',
      icon: F1_CAR,
      isImage: true,
      header: ['Points you Score', 'Formula 1', 'Formula 2', 'Formula E'],// 'Test Daily', 'County'],
      data: [
        { 'id': 0,  'rule': 'Not Classified Qualifying',  'g1': '-3',  'g2': '-3',  'g3': '-3' },
        { 'id': 1,  'rule': 'Not Classified Race',        'g1': '-15', 'g2': '-15', 'g3': '-15' },
      ]
    }
  ],
  motoGP:[
    {
      index: 0,
      name: 'Qualifying',
      icon: BIKE_RACE,
      isImage: true,
      header: ['Points you Score', 'Moto 2', 'Moto 3', 'Moto E', 'Moto GP'],
      data: [
        { 'id': 0, 'rule': 'Pole position',                     'g1': '+20',  'g2': '+20', 'g3': '+20', 'g4': '+20' },
        { 'id': 1, 'rule': '2nd place',                         'g1': '+18',  'g2': '+18', 'g3': '+18', 'g4': '+18' },
        { 'id': 2, 'rule': '3rd place',                         'g1': '+16',  'g2': '+16', 'g3': '+16', 'g4': '+16' },
        { 'id': 3, 'rule': 'Between 4th - 5th place',           'g1': '+15',  'g2': '+15', 'g3': '+15', 'g4': '+15' },
        { 'id': 4, 'rule': 'Between 6th - 8th place',           'g1': '+10',  'g2': '+10', 'g3': '+10', 'g4': '+10' },
        { 'id': 5, 'rule': 'Between 9th - 10th place',          'g1': '+8',   'g2': '+8',  'g3': '+8',  'g4': '+8'  },
        { 'id': 6, 'rule': 'Between 11th - 12th place',         'g1': '+5',   'g2': '+5',  'g3': '+5',  'g4': '+5'  },
        { 'id': 7, 'rule': 'Between 13th - 15th place',         'g1': '+3',   'g2': '+3',  'g3': '+3',  'g4': '+3'  },
        { 'id': 8, 'rule': 'Above 15th',                        'g1': '+1',   'g2': '+1',  'g3': '+1',  'g4': '+1'  }
      ]
    },
    {
      index: 3,
      name: 'Race Result',
      icon: BIKE_RACE,
      isImage: true,
      header: ['Points you Score', 'Moto 2', 'Moto 3', 'Moto E', 'Moto GP'],
      data: [
        { 'id': 0, 'rule': '1st Place',                    'g1': '+30', 'g2': '+30',  'g3': '+30',  'g4': '+30' },
        { 'id': 1, 'rule': '2nd Plae',                     'g1': '+25', 'g2': '+25',  'g3': '+25',  'g4': '+25' },
        { 'id': 2, 'rule': '3rd Place',                    'g1': '+18', 'g2': '+18',  'g3': '+18',  'g4': '+18' },
        { 'id': 3, 'rule': 'Between 4th - 5th Place',      'g1': '+15', 'g2': '+15',  'g3': '+15',  'g4': '+15' },
        { 'id': 4, 'rule': 'Between 6th - 8th Place',      'g1': '+10', 'g2': '+10',  'g3': '+10',  'g4': '+10' },
        { 'id': 5, 'rule': 'Between 9th - 10th Place',     'g1': '+8',  'g2': '+8',   'g3': '+8',   'g4': '+8'  },
        { 'id': 6, 'rule': 'Between 11th - 12th Place',    'g1': '+5',  'g2': '+5',   'g3': '+5',   'g4': '+5'  },
        { 'id': 7, 'rule': 'Between 13th - 15th Place',    'g1': '+3',  'g2': '+3',   'g3': '+3',   'g4': '+3'  },
        { 'id': 8, 'rule': 'Above 16th',                   'g1': '+1',  'g2': '+1',   'g3': '+1',   'g4': '+1'  } 
      ]
    },
    ,
    {
      index: 4,
      name: 'Race Laps',
      icon: BIKE_RACE,
      isImage: true,
      header: ['Points you Score', 'Moto 2', 'Moto 3', 'Moto E', 'Moto GP'],
      data: [
        { 'id': 0, 'rule': 'Completed 100%',          'g1': '+30', 'g2': '+30',  'g3': '+30',  'g4': '+30'},
        { 'id': 1, 'rule': 'Between 75% - 99.99%',    'g1': '+15', 'g2': '+15',  'g3': '+15',  'g4': '+15' },
        { 'id': 2, 'rule': 'Between 50% - 74.99%',    'g1': '+5',  'g2': '+5',   'g3': '+5',   'g4': '+5' },
        { 'id': 3, 'rule': 'Between 25% - 49.99%',    'g1': '-10', 'g2': '-10',  'g3': '-10',  'g4': '-10' },
        { 'id': 4, 'rule': 'Below 24.99%',            'g1': '-15', 'g2': '-15',  'g3': '-15' , 'g4': '-15' }
      ]
    },
    {
      index: 5,
      name: 'Race Gain & Lost',
      icon: BIKE_RACE,
      isImage: true,
      header: ['Points you Score', 'Moto 2', 'Moto 3', 'Moto E', 'Moto GP'],
      data: [
        { 'id': 0, 'rule': 'Each Position Gained (max +25 points)',                   'g1': '+5',  'g2': '+5',  'g3': '+5',  'g4': '+5'},
        { 'id': 1, 'rule': 'Beat Teammate (Diver Only)'            ,                  'g1': '+5',  'g2': '+5',  'g3': '+5',  'g4': '+5'},
        { 'id': 2, 'rule': 'Each Position Lost within Top 10 (max -25 points)',       'g1': '-5',  'g2': '-5',  'g3': '-5',  'g4': '-5' },
        { 'id': 3, 'rule': 'Each Position Lost Outside Top 10 (max -15 points)',      'g1': '-3',  'g2': '-3',  'g3': '-3',  'g4': '-3' }
      ]
    },
    {
      index: 6,
      name: 'Miscellaneous',
      icon: BIKE_RACE,
      isImage: true,
      header: ['Points you Score', 'Moto 2', 'Moto 3', 'Moto E', 'Moto GP'],// 'Test Daily', 'County'],
      data: [
        { 'id': 0,  'rule': 'Not Classified Qualifying',  'g1': '-3',  'g2': '-3',  'g3': '-3',  'g4': '-3' },
        { 'id': 1,  'rule': 'Not Classified Race',        'g1': '-15', 'g2': '-15', 'g3': '-15', 'g4': '-15' },
      ]
    }
  ]
});