

export const Terms = Object.freeze([
  {
    name: 'General',
    isExpandable: false,
    rules: [
      "Updated on 24th April, 2022.",
      "The FansChamp users are enforced to follow certain Terms &amp; Conditions associated with the usage of FansChamp. It is mandatory for the users to follow the terms and conditions of FansChamp while using the services provided by FansChamp:"
      + "\n\n\tThe FansChamp app is neither affiliated by nor associated to any sort of private or government Sports leagues and tournaments until and unless stated by the organization. In addition to this, the FansChamp app is not related and does not claim any official status with any of the official or non-official sports team or sportsperson."
      + "\n\n\tFansChamp used as a single entity anywhere in this document and all sections of T&C, should mean and constitutes of FansChamp.com website and FansChamp app on both android and iOS store."
    ]
  }, {
    name: 'Intellectual Property',
    isExpandable: true,
    rules: [
      "The FansChamp app comprises of a group of content developed by FansChamp, the partner’s of FansChamp, licensors, associates and/or Users of FansChamp. The partners, licensors, associates of FansChamp and/ or FansChamp itself hold the intellectual property (“Intellectual rights/ Property Rights”) in all software underlying FansChamp.com and entirely owns the Fun Features and material published on FansChamp.com, including (but not limited to) games, Contests, software, advertisements, written content, photographs, graphics, images, illustrations, marks, logos, audio or video clippings and Flash animation. The users are not permitted to modify, publish, transmit, participate in the transfer or sale of, reproduce, create derivative works of, distribute, publicly perform, publicly display, or exploit any of the materials or content published on FansChamp.com in any manner, either in whole or in part until and unless users have written license from rights team or authorities.",
      "The users can demand permission for modifying, publishing, transmitting, participating in the transfer of, reproducing, creating derivative works of, or publicly displaying any of the materials or content published on FansChamp.com.",
      "The users of FansChamp hold the sole responsibility of all the materials (whether publicly posted or privately transmitted) that they upload, post, e-mail, transmit or otherwise make available on FansChamp.com (\"Users' Content\"). The users of FansChamp assures that they hold and own all the Intellectual Property Rights in the User's Content and that none of the material uploaded or posted by the users as a part of the User's content does not hurt or harm any of the third-party rights. The users also ensure not to use or display any of the names, logos, marks, labels, trademarks, copyrights or intellectual and proprietary rights of any third party associated with or present on FansChamp.com. The users further accept and agree that FansChamp, its directors, employees, affiliates and assigns holds harmless and unanswerable towards any and/or all sort of costs, damages, loss and harm including towards litigation costs and counsel fees, in case of any claims made by any third party following the display or use of the names, logos, marks, labels, trademarks, copyrights or intellectual and proprietary rights on FansChamp.com, through the User's omissions.",
      "“Barred States” shall mean any state in the territory of India where online gaming has been prohibited under applicable laws or by an order of a court of competent jurisdiction.",
      "The users of FansChamp by accepting the terms and conditions permit FansChamp and its affiliates, partners, licensors and associates a worldwide, irrevocable, royalty-free, non-exclusive, sub-licensable license to use, reproduce, create derivative works of, distribute, publicly perform, publicly display, transfer, transmit, and/or publish Users’ Content for any of the following purposes:" 
      + "\n\tDisplaying Users’ Content on FansChamp.com"
      + "\n\tDistributing Users’ Content, either electronically or via other media, to other Users seeking to download or otherwise acquire it, and/or"
      + "\n\tStoring Users’ Content in a remote database accessible by end users, for a charge."
      + "\n\tThis license shall apply to the distribution and the storage of Users’ Content in any form, medium, or technology.",
      "Users consent to receiving communications such as announcements, administrative messages, and advertisements from FansChamp or any of its partners, licensors or associates."      
    ]
  }, {
    name: 'Usage of FansChamp.COM',
    isExpandable: true,
    rules: [
      "An individual using the services offered by the FansChamp App including the Fun Features offered, by participating in various contests and, games (including online fantasy sports gaming), organized on the FansChamp platform (“Contest(s)”) are bound to follow these Terms and Conditions, and all other rules, regulations, and terms of use defined by the FansChamp authorities.",
      "The FansChamp authorities have all the rights to modify or change the already defined Terms and Conditions, rules, regulations, and terms of use at any point in time, by posting the same on FansChamp.com. FansChamp constitutes the acceptance and agreement of the individuals using FansChamp services towards time to time amendments that can be made in the Terms and Conditions, rules, regulations, and terms of use already defined by FansChamp. The users can be informed about the amendments in these Terms and Conditions, rules, regulations, and terms of use by sending an email to the User's registered email address or posting notifications in the User accounts. In a circumstance wherein the user disagrees or does not accepts the amendments in the Terms and Conditions, rules, regulations, and terms of use can use the options provided in such an email or notification to indicate their non-acceptance within a defined time frame. If a user fails to indicate his/ her non-acceptance of the amendments within the defined time period the users are bound to accept the changes or modification in these Terms and Conditions, rules, regulations, and terms of use. In case the user disapproves/ rejects to accept the amendments in these Terms & Conditions, rules, regulations, and terms of use, then FansChamp at its sole & absolute discretion can refrain the user from using the services & Features provided by FansChamp.",
      "Various services offered by FansChamp might be associated with additional rules and regulations formulated in that respect. If the Terms and Conditions are inconsistent with the additional conditions formulated, the additional conditions shall prevail.",
      "Our Team may, at its sole and absolute discretion:"
      + "\n\ta) Restrict, suspend, or terminate any User’s access to all or any part of FansChamp.com or FansChamp.com Features;"
      + "\n\tb) Change, suspend, or discontinue all or any part of the Features;"
      + "\n\tc) Deactivate or delete a User’s account and all related information and files on the account;"
      + "\n\td) Reject, move, or remove any material that may be submitted by a User;"
      + "\n\te) Move or remove any content that is available on FansChamp.com;"
      + "\n\tf) Establish general practices and limits concerning use of FansChamp.com."
      + "\n\tg) Revise or make additions to the roster of players available for selection in a Contest on account of revisions to the roster of players involved in the relevant Sports Event."
      + "\n\th) Assign its rights and liabilities to all User accounts here under to any entity (post intimation of such assignment shall be sent to all Users to their registered email ids)",
      "FansChamp holds all the rights to restrict, suspend or terminate a user’s access to all or any part of FansChamp.com or the Features provided by FansChamp, deactivate or delete user account and information, delete user content without any prior notice to the user if any user is found or believed to be breaching the Terms and Conditions defined by FansChamp or using FansChamp.com or the features provided by FansChamp illegally or inappropriately. Further, FansChamp is authorized to take any technical or legal action against the user if necessary.",
      "In the event of suspension or deletion of a user's account due to negligence on the part of FansChamp, then, the company shall repay the platform fee charged for any of the Features provided by FansChamp, without any delay. However, no refund would be made in case of suspension or removal due to:"
      + "\n\ta) The breach of the Terms and Conditions defined by FansChamp or inappropriate use of FansChamp.com or Fun Features provided by FansChamp; or"
      + "\n\tb) Any circumstances beyond the reasonable control of FansChamp.",
      "The users hereby accepting the Terms and Conditions of FansChamp agree to receive communications including announcements, administrative messages, and advertisements from FansChamp or any of its partners, licensors or associates."
    ]
  }, {
    name: 'Third party sites, Services, and Products',
    isExpandable: true,
    rules: [
      "FansChamp is neither associated with nor responsible for the correspondence, transactions, and all other related activities between the user and third parties including payment providers and verification service providers on FansChamp.com. The users' involvement with third parties is entirely dependent on the terms and conditions, policies and services terms of these third parties and the users are solely responsible for reviewing the policies, terms and conditions, and the service terms of these third parties before transacting or availing any of the services. Accepting the Terms and Conditions of FansChamp the users agree that FansChamp is not responsible for any sort of damage or loss faced by the users due to any sort of transactions, correspondence and all related activities with any of the third parties. Any questions, complaints, or claims related to any third-party product or service should be directed to the appropriate vendor.",
      "There may be various links to other Internet sites owned and operated by third parties on FansChamp.com which may contain different conditions for users' use of the site. The FansChamp authorities do not hold any control or responsibilities for internet sites apart from FansChamp.com, and thus, it shall not be held responsible for any content posted on any third-party Internet site. Further, the involvement of FansChamp with any of the third-party content or links to third-party Internet sites is not related to any sort of endorsement by FansChamp of such third-party Internet site.",
      "FansChamp.com comprises a group of content created and posted by either by our team as well as various third parties. FansChamp does not hold responsible for the accuracy, integrity or the quality of the content created and posted by any of the third parties and such content may not be relied upon by the Users in terms of using the Features provided by the FansChamp platform or taking part in any of the contests hosted on the FansChamp platform."
    ]
  }, {
    name: 'Privacy Policy',
    isExpandable: true,
    rules: [
      "All sorts of user information collected by FansChamp in terms of personal details and payment information at the time of registration are subjected to the Privacy Policy of FansChamp which can be reviewed by the users at Privacy Policy."
    ]
  }, {
    name: 'User Conduct',
    isExpandable: true,
    rules: [
      "The Terms and Conditions, rules, regulations and terms of use defined by FansChamp for its users are mandatory to be followed by the users and in an event if the user fails to follow the Terms and Conditions, rules, regulations, and terms of use adequately the authorities of FansChamp holds all the rights to take action against such user, which might include (but not limited to):"
      + "\n\ta) Restricting, suspending, or terminating any User’s access to all or any part of FansChamp.com's Features;"
      + "\n\tb) Deactivating or deleting a User’s account and all related information and files on the account; or"
      + "\n\tc) Refraining from awarding any winning(s) to such User.",
      "By accepting the Terms and Conditions of FansChamp, the individual’s using services provided by FansChamp agree to provide true, accurate, current and complete information while registration and at other times when demanded by FansChamp. The individuals further provide consent to keeping their registration information updated.",
      "A single user is permitted to register or operate only one user account with FansChamp (inclusive of both web & app).",
      "The individuals using the services provided by FansChamp provide their consent for receiving all the communications from FansChamp by marking e-mails from FansChamp.com as part of their \"safe senders\" list. In an event, if any of the emails sent by FansChamp remains unread by the user because of being sent to the Spam or Junk folders, FansChamp shall not be held responsible.",
      "Any sorts of password issued by FansChamp to any of the individual using the services provided by FansChamp are confidential and should not be shared by the users with anyone else. The users hold the sole responsibility of maintaining the confidentiality of their accounts and passwords. The users offer their consent by agreeing to the Terms and Conditions of FansChamp to inform FansChamp about any unauthorized use of their passwords or accounts or any other breach of security.",
      "The user is solely responsible for the security and confidentiality of his/her payment accounts linked to his/her FansChamp account. In case of any misuse of the linked payment accounts of the user, FansChamp shall not be held liable; further, such misuse of the linked payment accounts of the user should be reported to FansChamp as soon as it comes to the notice of the user.",
      "The users ensure to exit/log-out of their accounts after the completion of each session. In case of any sort of loss or damage faced by the user due to the failure of abiding these rules and regulations, FansChamp shall not be held responsible.",
      "The individuals using services provided by FansChamp should neither conduct nor assist the usage of cheats, exploits, automation, software, bots, hacks or any unauthorized third-party software specifically formulated for modifying or intervening with FansChamp.com Features and/or FansChamp experience.",
      "The individuals using services offered by FansChamp platform provides their consent of not copying, modifying the technology and software associated with FansChamp and Features. Further, the users accept not to rent, loan, sell, assign, distribute, reverse engineer, grant a security interest in, or otherwise transfer any right to anyone of the associated technologies and software.",
      "The Terms and Conditions of FansChamp bounds the users to neither modify nor become a cause of modification of any of the files, technology or software associated with FansChamp.",
      "The users provide their acceptance to not to disrupting, overburdening, or promoting any kind of disruption or overburdening to:"
      + "\n\ta) The servers or computers offering or supporting FansChamp services or Features."
      + "\n\tb) The enjoyment of FansChamp.com's Features by other Users or persons.",
      "The Terms and Conditions of FansChamp do not permit the user to attain any sort of unauthorized access to the User accounts, Servers or networks associated to FansChamp.com's Features by any means except the user interface provided by FansChamp. The user must not indulge in activities like circumventing or modifying, attempting to circumvent or modify, or encouraging or assisting any other person to circumvent or modify, any security, technology, device, or software associated with the Features provided by FansChamp.",
      "The users provide their acceptance for not using the FansChamp platform for any sort of inadequate activities including (but not limited to):"
      + "\na) To engage in any obscene, offensive, indecent, racial, communal, anti-national, objectionable, defamatory or abusive action or communication;"
      + "\nb) To harass, stalk, threaten, or otherwise violate any legal rights of other individuals;"
      + "\nc) To Transmit files that contain viruses, corrupted files, or any other similar software or programs that may damage or adversely affect the operation of another person's computer, FansChamp.com, any software, hardware, or telecommunications equipment;"
      + "\nd) To advertise, offer or sell any goods or services for any commercial purpose on FansChamp.com without the express written consent of FansChamp;"
      + "\ne) To Transmit content regarding services, products, surveys, contests, pyramid schemes, spam, unsolicited advertising or promotional materials, or chain letters;",
      "The users are bound to accept that they will neither support or conduct any sort of attack including (but not limited to) distribution of a virus, denial of service, or other attempts to interrupt the services provided by FansChamp or the use or enjoyment of other FansChamp users."
    ]
  }, {
    name: 'Registration',
    isExpandable: true,
    rules: [
      "To register on FansChamp Platform user has to provide its own email id on which an OTP will be sent and once verified it can log in.",
      "In order to register for the Contest(s), Participants are required to provide the following information accurately:"
      + "\n\ta) Date of birth"
      + "\n\tb) State"
      + "\n\tc) Pin Code of Area of residence"
      + "\n\td) Phone Number"
      + "\n\te) Email id",
      "The “Full Name” and “Date of Birth” mentioned by the user for the purpose of verification should match with the Name and Date of Birth mentioned on all the documents required to be uploaded for verification purposes.(PAN Card)",
      "The people participating in activities, services or features provided by FansChamp are required to provide their approval stating they have carefully read all the terms and conditions and will comply with all the rules, regulations and terms of use of FansChamp.",
      "The participants entering any contest(s) organized by FansChamp as a resident of Assam, Odisha, Telangana, Nagaland, Sikkim, Andhra Pradesh & Tamil Nadu will not be permitted to sign up for any round in the paid version of the Contest as elaborated below. In case of audits from FansChamp, if a user is found to be a resident of Assam, Odisha, Telangana, Nagaland, Sikkim, Andhra Pradesh & Tamil Nadu but not stated at the time of registration then FansChamp at its sole and absolute discretion holds the rights to take action against such user including (not limited to) blocking of user account, deletion or deactivation of user account, and resetting the user account to initial level by deleting all the logs, refers & reducing the amount present in the User Account to “zero.”",
      "The participants who are above the age of 18 years and have successfully completed their registration process by entering valid and adequate information in the registration form are sent a confirmation email comprising their login details.",
      "Valid email id and mobile number is given for registration.",
      "In case of PAN card verification, any scanned image of a photocopy of the users’ PAN card will not be acceptable. For PAN card verification it is mandatory to upload a full image of the original PAN card.",
      "In case, you are uploading an E-PAN card for PAN card verification process then it is mandatory to upload a full scanned image of the E-PAN card (both front and back)."
    ]
  }, {
    name: 'Contest(s), Participation and Prizes',
    isExpandable: true,
    rules: [
      "The FansChamp platform conducts various contest(s) as a part of Features offered by FansChamp. FansChamp offers fantasy cricket, fantasy football, fantasy kabaddi, fantasy volleyball, fantasy basketball, fantasy Formula Race, fantasy Moto Race and fantasy hockey as a part of its features. The contests conducted by FansChamp allows the users to participate in the Services and Features by formulating their own fantasy team(s) comprising of on-field players involved in the on-field match (as applicable), series or tournament to which the online fantasy sports gaming relates. The teams of the individual users are given with points on the basis of the performance of on-field players at the end of a designated match, round or tournament of the Contest(s). The user whose team attains the highest aggregate of points will be declared as the winner of the contest. FansChamp is authorized to declare more than one Winner and distribute winnings to such Winners in increasing order of their Team's aggregate score at the end of the designated round(s) of the Contests in the event of various pre-specified contest(s). The Contest(s) organized as a part of  Features would comply to the Terms and Conditions, rules and regulations mentioned below:"
      + "\n\"Fantasy Rules\" available at “How To Play” rules and regulations (including rules and regulation in relation to any payments made to participate in the Contest(s); and all Participants agree to abide by the same.",
      "FansChamp presently offers paid versions of the contests wherein the users can participate by paying a pre-defined amount on the relevant Contest page as a part of contest entry amount. An individual with the highest aggregate of scores at the end of the pre-determined round would be eligible for winning a pre-designated winning, as stated on the relevant Contest(s) page.",
      "An individual is authorized to make different teams for participating in Contest(s) conducted by the FansChamp platform. However, until and unless FansChamp specifies a contest as Multiple Entry contests, the users are bound to submit only one Team to participate in the contest organized by FansChamp. In the context of the Multiple Entry Contest, the participants can enter in the contest with more than one team, but the user will not be allowed to edit or revise the Teams so submitted for participation in such Multiple Entry Contest after the deadline. Further, FansChamp is authorized to limit the number of multiple teams allowed in a contest or the number of teams a single user account can create for entering a Multiple Entry Contest.",
      "FansChamp is authorized to charge a pre-defined contest entry amount for allowing the users to access the Fun Features in relation to the Contest(s). In a situation wherein a participant is found to be a resident of Assam, Odisha, Telangana, Nagaland, Sikkim, Andhra Pradesh & Tamil Nadu, by FansChamp, the user will not be allowed to proceed to sign up for the round or league and may not participate in any paid version of the Contest(s).",
      "FansChamp offers confirmed as well as non-confirmed leagues on the platform. The confirmed leagues are the ones that are not cancelled even when they do not meet the pre-defined participant requirements, whereas non-confirmed leagues are cancelled due to unfilled spots.",
      "FansChamp holds all the rights to abandon a contest or adjust or change the deadline of a particular match or in unavoidable circumstances that stand beyond the control of FansChamp. The scenarios wherein the match will not be abandoned and the deadline can be adjusted include (but are not limited to) the ones mentioned below:"
      + "\n\nThe Official Match Start Time is before FansChamp’s Deadline"
      + "\nThis scenario can differ for cricket and other sports as:"
      + "\n\nFor Race Sports"
      + "\nAs the Race span across multiple days and one race consists of multiple events, hence the match start period is considered 10 minutes before the first race event such as Qualifying, sprint, race etc."
      + "\n\nOther Sports apart from Cricket:"
      + "\nA difference of 10 minutes (maximum) between the official match start time and FansChamp’s deadline can be adjusted by the platform. However, if the difference is of more than 10 minutes, i.e., the official match starts more than 10 minutes before FansChamp’s deadline, then, the contest will be abandoned on FansChamp"
      + "\n\nFor Cricket:"
      + "\nFansChamp, at its sole and absolute decision, can adjust the deadline of a contest on FansChamp by 10 minutes or 3 overs (whichever is less) before the beginning of the official match"
      + "\nIn case FansChamp fails to cross-check or verify the official match start time from any of its reliable third-party sources or from the official websites, then, FansChamp’s deadline for the particular contest will be adjusted in such a way that a maximum of 3 overs is bowled in the adjusted time being."
      + "\n\nThe Official Match Start Time is after FansChamp’s Deadline"
      + "\nFansChamp at its sole and absolute discretion can abandon, cancel or delay the start of a contest on FansChamp by extending the deadline in case of certain unavoidable circumstances such as (but not limited to) delay in the toss, weather conditions, technical glitches, non-appearance of the teams on the field, natural disasters, etc.",      
    ]
  }, {
    name: 'Contest Formats',
    isExpandable: true,
    rules: [
      "FansChamp conducts Contest(s) wherein the users can participate in a Contest with other Users without any restriction on participation. The contest comply to various Terms and Conditions including (but not limited to):"
      + "\na) FansChamp in the format of the Contest(s) can offer any pre-defined numbers of participants that can take part in the contest."
      + "\nb) FansChamp is authorized to conduct the Contest(s) as a paid contest and might announce the winner at the end of the contest."
      + "\nc) FansChamp can pre-define the number of participants required to make the Contest(s) operational. In an event wherein the number of participants required to make the contest operation equalizes with the number of users participating in the contest, the Contest(s) organized by FansChamp will begin at the scheduled start of the Contest(s). However, in a situation wherein the number of participants is less than the number required at the time of beginning the Contest(s), the Contest(s) will be discarded, and the contest entry amount will be refunded to the participants without any charge or deduction."
    ]
  }, {
    name: 'Legality of Game of Skill',
    isExpandable: true,
    rules: [
      "Games of skill are legal, as they are excluded from the ambit of Indian gambling legislation including, the Public Gambling Act of 1867.Games of skill are legitimate businesses protected by Article 19(1)(g) of the Indian Constitution, which guarantees the right to carry on an occupation, trade or business.",
      "The Contest (s) described above are games of skill as success of Participants depends primarily on their superior knowledge of the games of cricket, football, kabaddi, volleyball, Basketball, Hockey, Formula1 Formula2, Formula3, FormulaE, MotoGP, Moto2, MotoE and MotoE statistics, knowledge of players’ relative form, players’ performance in a particular territory, conditions and/or format (such as ODIs, test cricket and Twenty20 in the online cricket fantasy gaming), attention and dedication towards the Contest(s) and adroitness in playing the Contest(s). The Contest(s) also requires Participants to field well-balanced sides with limited resources and make substitutions at appropriate times to gain the maximum points.",
      "By participating in this Contest(s), each Participant acknowledges and agrees that he/she is participating in a game of skill."
    ]
  }, {
    name: 'Eligibility',
    isExpandable: true,
    rules: [
      "The Contest(s) are open only to persons above the age of 18 years.",
      "The Contest(s) are open only to persons, currently residing in India.",
      "FansChamp may, in accordance with the laws prevailing in certain Indian states, bar individuals residing in those states from participating in the Contest(s). Currently, individuals residing in the Indian states of Assam, Odisha, Telangana, Nagaland, Sikkim, Andhra Pradesh & Tamil Nadu may not participate in the paid version of the Contest as the laws of these states bar persons from participating in games of skill where participants are required to pay to enter. In case, amid detailed audits on the end of FansChamp any user is found to be a resident of any of the aforementioned states and is found to be hiding it from FansChamp at the time of registration then actions mentioned earlier including (but not limited to) block, account reset, account deletion or deactivation shall be initiated. In an event, the state laws of these states change and the residents of these states are allowed to participate in the paid versions of Game of Skills, the users (which were blocked earlier for being a resident of these states) shall be treated as new registrations and in no case, previous winnings shall be considered and any claim of such sort will not be entertained in any case.",
      "Persons who wish to participate must have a valid email address.",
      "Persons who wish to participate must have a valid phone number.",
      "Only those Participants who have successfully registered on the FansChamp.com as well as registered prior to each round in accordance with the procedure outlined above shall be eligible to participate in the Contest and earn winnings."
    ]
  }, {
    name: 'Selection and verification of winners and conditions relating to the winnings',
    isExpandable: true,
    rules: [
      "Selection of Winners"
      + "\nFansChamp will select winners on the basis of computation and tabulation of scores in a designated round (which may last anywhere between one day to 5 days and an entire tournament) of the Contest(s). An individual whose team will score the highest aggregate of points based on the on-field performance of their selected players will be declared as winners by FansChamp. However, in an event wherein the number of pre-designated winners is defined to be more than one by FansChamp, then, the winners will be chosen on the basis of increasing order of their aggregate scores at the end of the designated round of the Contest. FansChamp shall pre-define the number of winners to be announced and winnings to be distributed on the Contest page before the beginning of the game. Any individual identified by FansChamp as creating a team on behalf of other users will be disqualified from the Contest(s). In an event wherein there's a tie between the winners, then the winning users shall be declared as the winners, and the winnings shall be equally divided among such Participants. If the users have not complied to the Terms and Conditions defined by FansChamp, then, FansChamp shall not be liable to pay any winnings in relation to the use of the FansChamp.com, Contest, \"Fantasy Rules,\" etc.",
      "\nContacting Winners"
      + "\nFansChamp or any other third-party organizing Contest(s) as a part of  Features provided by FansChamp shall contact the winners of the contest(s) through email, mobile or both provided by the user at the time of registration. FansChamp shall detail the users about the documents required for the collection of the winnings at this stage. As a general practice, winners will be required to provide the following documents:"
      + "\nOriginal scanned copy of the User’s PAN card."
      + "\nUser’s bank account details and proof of the same.",
      "An individual shall not be allowed by FansChamp to withdraw his/her winnings without the submission and verification of the above mentioned documents within the time-period defined by FansChamp. By accepting these Terms and Conditions, the users ensure that the documents provided at the time of verification are the true copies of the original documents.",
      "The users are bound to provide adequate and complete details at the time of registration. FansChamp shall not be held responsible for any sort of communication, or omissions that might lead to a situation wherein the results may not be communicated to the Winner.",
      "FansChamp shall publish the list of the participants on a separate web-page on FansChamp.com alongside separately informing the winners through e-mail. If ever happens that a winner's name has been displayed on the separate webpage, but he/she has not received any communication e-mail from FansChamp, then the participant should contact FansChamp within the time specified on the webpage.",
      "\nVerification process"
      + "\nFansChamp is authorized only to permit the participants who have successfully completed the verification process by providing the required documents within the time limit specified by FansChamp to withdraw/receive their accumulated winnings (or any part thereof). Any request for extending the time-limit for verification and document submission shall not be accepted by FansChamp.",
      "FansChamp is authorized to disqualify any winner from withdrawing their accumulated winnings post scrutinizing all documents (or any part thereof) on the following grounds:"
      + "\nFansChamp identifies that any of the document or information provided by the user at the time of verification is inadequate, incorrect, misleading, false, fabricated, incomplete or illegible; or"
      + "\nParticipant does not fulfill the Eligibility Criteria specified in Clause 10 above; or"
      + "\nAny other ground.",
      "\nMiscellaneous"
      + "\nWinning distribution and awarding related decisions undertaken by FansChamp shall be final, binding and non-contestable. The individuals participating in the paid formats of the Contest(s) ensure that they are not the residents of Assam, Odisha, Telangana, Nagaland, Sikkim, Andhra Pradesh & Tamil Nadu. FansChamp hold all the rights to disqualify any participant playing the paid formats of the Contest(s) if identified as a resident of the above mentioned Indian states and forfeit any winnings earned by such participants. Further FansChamp is authorized to suspend or terminate the accounts of such participants at its sole and absolute discretion."
      + "\nFansChamp holds all the rights to disqualify a participant alongside forfeiting the winnings of such participants at its sole and absolute discretion, in an event wherein an individual below the age of 18 years is identified playing the paid formats of the Contest(s) organized by FansChamp. In addition to this, FansChamp can suspend or terminate the accounts of such participants. FansChamp shall not be held liable for the quality, suitability, and merchantability of the winnings as FansChamp makes no warranties about the same to the extent permitted by legal authorities."
      + "\nFansChamp is authorized to modify or change the winnings offered to the participants declared as the winners of Contest(s) organized by FansChamp, and the participants not raise any claim or question the decision of FansChamp related to the winning modification."
      + "\nFansChamp shall not be held responsible or liable for any sort of damage or loss to the winnings occurring at the time of transportation."
      + "\nAny sort of transportation charges including shipping and courier are to be undertaken by the customers in respect of the winnings." 
      + "\nIn case of the cash winnings, the transaction charges fall under the part of the customers."
      + "\nThe winnings given to the participants declared as the winners of Contest(s) organized by FansChamp are non-transferable and non-refundable. The winners can neither exchange/redeem those winnings for cash or kind."
    ]
  }, {
    name: 'Publicity',
    isExpandable: true,
    rules: [
      "In an event wherein an individual declared as the winner of Contest(s) accepts the winnings offered by FansChamp, then affiliates of FansChamp and the company itself is authorized to use the name, likeness, voice and comments of the winner for various purposes including (but not limited to) advertising, promotion of the company in any media worldwide, alongside trading without any further permissions or consents and/or additional compensation being given to the participant. By accepting the Terms and Conditions, the winners of the Contest(s) provide their consent for being available for promotional purposes as planned and desired by FansChamp without any charge. The promotional and advertising events can include (but not limited to) press events, internal meetings and ceremonies/functions wherein the dates for the promotional events will be solely decided by the team of FansChamp."
    ]
  }, {
    name: 'General conditions',
    isExpandable: true,
    rules: [
      "In a rare event wherein FansChamp identifies the need of any governmental, statutory or regulatory compliances or approvals for organizing any Contest(s) or the company identifies any on-going Contest(s) breaching the legal guidelines or being prohibited by the legal authorities, then, FansChamp hold all the rights to cancel or withdraw such Contest(s) without any prior notice to the participants or the winners. The individuals using the services provided by FansChamp agree not to make any claims related to such cancellation or withdrawal in any manner. The employees, directors, affiliates, relatives and family members of FansChamp, will not be eligible to participate in any Contest(s)."
    ]
  }, {
    name: 'Dispute and dispute resolution',
    isExpandable: true,
    rules: [
      "Any sort of disputes associated with Fun Features or services provided by FansChamp including the Contest(s), the construction, validity, interpretation and enforceability of these Terms and Conditions, or the rights and obligations of the User(s) (including Participants) or FansChamp shall be undertaken by the courts of competent jurisdiction at Cuttack, Odisha. All sort of disputes, issues, and questions shall be addressed by the court competent jurisdiction in alignment with the laws of the Republic of India.",
      "In a circumstance wherein a legal dispute arises (legal issue or question) between two parties, the party raising the dispute is bound to provide written notification (\"Notification\") to the other party involved in the dispute. The parties involved in the dispute should initially focus on resolving the issue post receiving the Notification. However, if both the parties fail to resolve the dispute through mutual discussion within 15 days of receiving the Notification, the dispute shall be resolved by arbitration.",
      "The place of arbitration shall be Cuttack, Odisha, India. All arbitration proceedings shall be conducted in English and in accordance with the provisions of the Arbitration and Conciliation Act, 1996, as amended from time to time.",
      "During the arbitration process, the parties involved in the dispute will be complied to follow the arbitration award. Additionally, the parties involved in the disputes will have to bear costs of arbitration on their own and will have to equally share the fees of the arbitrator unless stated otherwise by the arbitral tribunal. The arbitrator is allowed to pass interim orders and awards, including the orders for specific performance and such orders would be enforceable in competent courts. The arbitrator is bound to provide a rational award."
    ]
  }, {
    name: 'Release and limitations of liability',
    isExpandable: true,
    rules: [
      "The users agree to access and use the Features provided by FansChamp voluntarily and at their sole risk. Further, accepting not to hold FansChamp responsible or liable on account of any loss or damage sustained including (but not limited to) any accident, injury, death, loss of property by Users or any other person or entity during the course of access to the Features (including participation in the Contest(s)) or as a result of acceptance of any winnings.",
      "The individuals using the services or Features provided by FansChamp reimburse FansChamp, and/ or any of its directors, employees, partners, associates, and licensors, from and against all the responsibility, liability, cost, loss or expense faced by the user due to their access to the Features including (but not limited to) personal injury and damage to property and whether direct, indirect, consequential, foreseeable, due to some negligent act or omission on their part, or otherwise.",
      "FansChamp shall not be held responsible either jointly or individually, for any sort of errors or omissions, neither on behalf of itself nor on behalf of third parties in terms of the winnings.",
      "By accepting these terms and conditions the user accepts that he/she shall be held solely responsible for any consequences arising due to an event wherein a user carries out any sort of illegal act due to the non-conformity with these Terms and Conditions and other rules and regulations in relation to Features, including the provision of an incorrect address or other personal details.",
      "The users provide their consent to defend, and hold FansChamp harmless from any third party/entity/organization claims arising due to, or associated with user’s involvement with FansChamp.com or his/her participation in any Contest(s) organized on FansChamp platform.",
      "The individuals using the Features and services provided by FansChamp, by accepting these Terms and Conditions agree that FansChamp shall not be held responsible alongside waiving and releasing each and every right or claim, all actions, causes of actions (present or future) each of them has or may have against FansChamp, its respective agents, directors, officers, business associates, group companies, sponsors, employees, or representatives for all and any injuries, accidents, or mishaps (whether known or unknown) or (whether anticipated or unanticipated) faced due to the Features or associated with the Contests or the winnings of the Contests."
    ]
  }, {
    name: 'Disclaimers',
    isExpandable: true,
    rules: [
      "To the extent permitted under law, neither FansChamp nor its parent/holding company, subsidiaries, affiliates, directors, officers, professional advisors, employees shall be responsible for the deletion, the failure to store, the missing delivery or the untimely delivery of any information or material.",
      "In alignment to the legal bodies, FansChamp shall not be held responsible for any harm faced due to downloading or accessing any information or material, the quality of servers, games, products, Features or sites, cancellation of competition and winnings. FansChamp does not hold any responsibility if a User pays for access to one of FansChamp's Features and the user shall not be provided any refund as a result of, any inaccessibility that is caused by FansChamp's maintenance on the servers or the technology that underlies our sites, failures of FansChamp's service providers (including telecommunications, hosting, and power providers), computer viruses, natural disasters or other destruction or damage of our facilities, acts of nature, war, civil disturbance, or any other cause beyond our reasonable control. Further, FansChamp disclaims any sort of warranty for the content provided on FansChamp.com(s). FansChamp.com(s) content is distributed on an \"as is, as available\" basis.",
      "The users are solely responsible for any sort of material accessed, downloaded or otherwise obtained through FansChamp.com and holds the sole responsibility for any potential damage to their computer system or loss of data that occurs as a result of downloading or accessing any such material.",
      "FansChamp shall make best endeavors to ensure that the FansChamp.com(s) is error-free and secure, however, neither FansChamp nor any of its partners, licensors or associates makes any warranty that:"
      + "\nthe FansChamp.com(s) will meet Users’ requirements,"
      + "\nFansChamp.com(s) will be uninterrupted, timely, secure, or error-free"
      + "\nthe results that may be obtained from the use of FansChamp.com(s) will be accurate or reliable; and"
      + "\nthe quality of any products, Features, information, or other material that Users purchase or obtain through FansChampcom(s) will meet Users’ expectations.",
      "FansChamp holds all the rights to rectify any errors identified in the determination of Winners or in the transfer of amounts to a User's account, using various method as it deems fit, including (but not limited to) through a set-off of the mistaken payment from amounts due to the User or deduction from the User's account of the amount of mistaken payment. In such events, FansChamp agrees to notify the user about the error and the rectification measure adopted to address the error.",
      "Complying to the legal bodies and authorities neither FansChamp nor its partners, licensors or associates disclaims any sort of responsibility for any direct, indirect, incidental, special, or consequential damages arising out of the use of or inability to use our sites, even if we have been advised of the possibility of such damages.",
      "FansChamp is authorized to cancel any Features, events or Contest(s) requiring specific permission or authority from any statutory authority or any state or the central government, or the board of directors in an event wherein such permission or authority is either not obtained or denied either before or after the organization of the relevant Features, events or Contest(s).",
      "By accepting these Terms and Conditions, the users agree not to make any demands, or claims in the event of suspension or closure of any Services, events or Contests organized/provided by FansChamp."
    ]
  }, {
    name: 'Miscellaneous',
    isExpandable: true,
    rules: [
      "In alignment with certain legislation, FansChamp is bound to notify the users regarding various events. In this regard, the users by accepting these Terms and Conditions provide their consent that such notification issued and posted by FansChamp on either FansChamp.com or conveyed through the e-mail provided by the customer while registration, will be considered adequate and authorized, and will be effective upon the customers. Further, FansChamp shall not be held responsible for any loss of information or failing to notify the user, if the user fails to provide adequate and accurate information at the time of registration.",
      "FansChamp shall not be held responsible for delay or failure in notifying the users, organizing/providing services and Contest(s) because of outside reasons considered to be out of the reach of FansChamp, including (but not limited to) any failure to perform due to unforeseen circumstances or cause beyond FansChamp's control such as acts of God, war, terrorism, riots, embargoes, acts of civil or military authorities, fire, floods, accidents, network infrastructure failures, strikes, or shortages of transportation facilities, fuel, energy, labor or materials or any cancellation of any match to which a Contest relates. If such an event ever happens then, FansChamp holds all the rights to cancel any related Contest(s) and to process an appropriate refund for all Participants.",
      "FansChamp’s failure to exercise or enforce any right or provision of these Terms and Conditions shall not constitute a waiver of such right or provision.",
      "By accepting these Terms and Conditions, the users provide their consent that regardless of any statute or law to the contrary, any claim or cause of action faced due to or associated with the use of FansChamp.com or these Terms must be filed within thirty (30) days of such claim or cause of action arising or be forever barred.",
      "These Terms and Conditions, including all terms, conditions, and policies that are incorporated herein by reference, constitute the entire agreement between the User(s) and FansChamp Fantasy Sports Private Limited and govern your use of the FansChamp.com, superseding any prior agreements that any User may have with FansChamp Gaming Private Limited.",
      "In an event wherein any part of these Terms and Conditions defined by FansChamp is identified to be indefinite, invalid, or otherwise unenforceable, the rest of these Terms and Conditions shall continue in full force.",
      "FansChamp is authorized to moderate, restrict or ban the use of the FansChamp.com, by any particular user or generally, in alignment to the FansChamp’s policy/policies from time to time, at its sole and absolute discretion and without any notice.",
      "FansChamp holds all the rights to permanently close or temporarily suspend any Fun Features (including any Contest(s)).",
      "All your Transaction history (including but not limited to Amount Added, Winnings, League Joined, etc.) will only be available for the last 15 days. In case you’ve any query related to any of your transaction please reach our support team within 7 days of occurrence of the issue."
    ]
  }, {
    name: 'Refund',
    isExpandable: true,
    rules: [
      "Any amount once added to the FansChamp account of user can't be refunded back to the user in any case, and the amount can be used to join the leagues, features and Contest(s) organized by FansChamp.",
      "The individuals using the services and Features provided by FansChamp are not permitted to cancel any transaction which has been once confirmed.",
      "FansChamp reserves the rights to refund the transaction amount to the user in the abovementioned case at its sole and absolute discretion post deducting applicable cancellation charges and taxes. Further, amid the transaction process, the users should take a note of the additional terms and conditions which significantly govern the transaction. In an event wherein the clauses defined in additional terms and conditions conflict with the present Terms and Conditions defined by FansChamp, the additional terms, and conditions associated with transactions shall prevail. The refund is only applicable to the amount used to join any league and shall be refunded to the FansChamp User Account source, as deducted.",
      "In case of any unforeseen event, e.g. Force manure, event cancellation, or technical errors and others, only league joining amount will be refunded as used to join the league, there will be no claim on the winning as per the rank."
    ]
  }, {
    name: 'Standard terms and conditions of promotions',
    isExpandable: true,
    rules: [
      "The users are bound to comply with the Promotion Terms defined by FansChamp in respect of such Promotion (\"Promotion Terms\") and these Standard Terms while participating in any sort of promotions. Further, the users while participating in any Promotion accepts and agrees to comply with terms and conditions, and privacy policies of the FansChamp.",
      "The Promotions are only open to users in India. Participation in the Promotions by proxy is not permitted.",
      "Participation in the Promotions is voluntary.",
      "Participation in one Promotion does not guarantee that such user will be eligible to participate in another Promotion.",
      "An individual using the services and Features provided by FansChamp shall participate and avail the Promotions offered by FansChamp only through one account. A user already registered with FansChamp is not permitted to create a new account or use various accounts for participating in a Promotion.",
      "The individuals using the services provided by FansChamp, intending to participate in a Promotion may be required to verify their mobile number and other account details in alignment with the Promotion Terms for such Promotion.",
      "The individuals willing to participate in the Promotion of FansChamp with their numbers registered on the National Do Not Call Registry (“NDNC Registry”), need to de-register their numbers from the NDNC Registry until the completion of such Promotion (including the delivery of Bonus Amount (if any) or the free-entry (if any) under such Promotion). FansChamp shall not be held responsible for any loss or damage in the event of notification failure to the users from FansChamp arising due to the participant’s association with NDNC list. The users intending to participate in the Promotion with their numbers registered on to the NDNC list shall not be authorized to make any claim or raise any complaints against FansChamp if they do or do not receive any call or SMS with respect to their participation and all other matters pertaining to a Promotion.",
      "The verification process may require you to submit personal information about yourself. The users willing to participate in the Promotion provide their consent to receive communication from FansChamp. Any sort of user information including identity and contact details collected by FansChamp amid the registration, verification or during the user usage of FansChamp.com shall be subject to FansChamp’s Privacy Policy.",
      "FansChamp reserves rights to disqualify any user at its sole and absolute discretion from a Promotion in an event wherein the user is identified to be engaged in any inadequate, illegal, or unlawful conduct (with regard to any of the Promotions or otherwise).",
      "The users can use the Bonus Amount (if any) deposited in their accounts as a part of FansChamp promotional offers for paying 5% of the contest entry amount to join various cash contests. However, the Bonus Amount (if any) present in the accounts of the users cannot be withdrawn or transferred to any other cash balance section of User Account held by the user with FansChamp or to any third-party account or to any bank/payment instrument account. The bonus amount (if any) shall expire at the end of 14 days from the date of credit of the bonus amount (if any).",
      "The deposit of the Bonus Amount (if applicable) or the grant of the free-entry (if any) shall be at the sole discretion of FansChamp and shall be subject to the user’s compliance with these Standard Terms and the applicable Promotion Terms. FansChamp is authorized to change or substitute the Bonus Amount (if any) or free-entry (if any) provided to the users under a Promotion at any time without any prior notice to the users. The users are not permitted to substitute Bonus Amount (if any) or free-entry (if any) for other items or exchange for cash.",
      "In an event wherein FansChamp identifies or believes that a user participating in the Promotion has violated the Standard Terms, Promotion Terms or the terms and conditions of the FansChamp fantasy game(s), FansChamp is authorized to withhold or forfeit the benefits of a Promotion (including a free-entry or Bonus Amount due to a participant or any winnings earned by the participant by using such benefits).",
      "Mere participation in a Promotion does not entitle the participant to receive any free-entry or Bonus Amount(s) indicated as winnings under such Promotion.",
      "FansChamp's decision regarding the winners and winnings in the context of the Promotion(s) will be final and binding. Further, FansChamp shall not entertain any sort of correspondence, objection, complaints, etc. in this regard.",
      "Each Promotion cannot be clubbed with any of other contest/offer/promotion that is running simultaneously and organized or conducted by FansChamp.",
      "FansChamp holds all the rights to change/modify/or withdraw any of the Promotions and/or change these Standard Terms and/or the Promotion Terms without any prior notice to the users.",
      "FansChamp does not make any commitment, express or implied, to respond to any feedback, suggestion and, or, queries of the participants of the Promotions.",
      "Notwithstanding anything contained herein, the aggregate liability of FansChamp to a participating user in relation to any Promotion for any reason whatsoever shall not exceed ₹100 or the joining fee (whichever is lower)."
    ]
  }, {
    name: 'Standard terms and conditions of "BONUS" programs.',
    isExpandable: true,
    rules: [
      "Refer and earn"
      + "\nThe Refer & Earn program of FansChamp lets the user earn cash bonus by inviting his/her friend to join FansChamp. The referrer can earn up to ₹500 cash bonus in the FansChamp BONUS section of their wallet under this promotion scheme in compliance with various T&C",
      "\nEligibility",
      + "\nThe users that have an account registered and a verified gaming account with FansChamp are eligible to participate in the pay-to-play FansChamp fantasy Sports game according to the Terms and Conditions defined by FansChamp have downloaded and installed the Application on their respective mobile devices, are considered as eligible for participating in the Program. FansChamp neither encourages nor permits participation in the program through a proxy."
      + "\nFansChamp does not permit any user to register or operate more than one gaming account with FansChamp. The participation in the Program is voluntary, and no user is allowed to participate in the Program with more than one user account."
      + "\nThe users while participating in the Program provide their consent to abide by these Terms and Conditions of FansChamp."
      + "\nThe users in order to participate in the Refer & Earn Program need to download and install FansChamp's mobile application on their mobile devices. The users post downloading and installing the mobile application on their mobile devices will be provided with a unique code, which the user can distribute among his/her friends inviting them to join FansChamp by creating and registering an account with FansChamp, and downloading and installing the mobile application of FansChamp on their mobile devices. The referred friend (Referent) of the user (Referrer) already registered with FansChamp, can use the received code by directly download and install the FansChamp's mobile application on his/her device, create and register an account with FansChamp, and while regestering enter the unique code shared by the Referrer in Referral Code section."
      + "\nThe bonus provided to the user under the Refer & Earn program of FansChamp can only be earned by the user who referred any friend in an event they abide by other terms specified in these Terms, including: (i) The referred friend of the user should not be already registered with FansChamp, (ii) both the user and its referred friend are eligible for participating in the pay-to-play Sports contest organized by FansChamp in alignment to the Terms and Conditions defined by FansChamp, (iii) the referred friend of the user should successfully create, register and verify an account with FansChamp using the unique code provided by the Referrer; and (iv) the user and his/her referred friend should agree to the license agreement of FansChamp's mobile app, and should download and install the FansChamp's mobile application in accordance to their mobile device platforms. The already registered users of FansChamp as defined in these Terms and Conditions refers to an individual who presently operates or holds an account registered with FansChamp or a user who operated or had an account with FansChamp at some point of time."
      + "\nFansChamp neither permits nor encourages an already registered user of FansChamp to register a new account under a new name in order to be eligible for the promotion.FansChamp is authorized to take adequate actions if a user registering with the Platform is identified to be an existing user of FansChamp."
      + "\nBy accepting these terms and conditions, the users provide their consent to provide all the information required for the verification of a Referrer/Referent including personal information about the user (Referrer/Referent) and documents identifying the Referrer/Referent. Further, the Referrer provides his/her consent for receiving communication from FansChamp alongside permitting FansChamp to directly communicate with Referents referred by the already registered user of FansChamp. Any sort of user information collected by FansChamp amid the course of user's use of the Website shall be subject to FansChamp's Privacy Policy."
      + "\nAny user won't be allowed to make any withdrawals from his gaming account registered with FansChamp until the completion of the verification process of the Referrer/Referent. However, the Referent can also verify his/her personal and contact information at the time of registration of an account with FansChamp through the FansChamp's mobile application."
      + "\nFansChamp is authorized to disqualify any Referrer/Referent at its sole and absolute discretion, in an event if FansChamp identifies any Referrer/Referent to be engaged in any illegal, inadequate or unlawful conduct.",
      "\nReferrer Bonus"
      + "\nWhen your friend verify PAN card"
      + "\nYou earn ₹50, Can view the Bonus in FansChamp BONUS section of FansChamp wallet."
      + "\nWhen your friend verify Bank account"
      + "\nYou earn ₹50, Can view the Bonus in FansChamp BONUS section of FansChamp wallet."
      + "\nWhen your friend add cash"
      + "\nEvery time your friend adds ₹50 or more you will receive 5% of the amount in the FansChamp BONUS section of your FansChamp wallet."
      + "\nMaximum bonus for promotion to be earned = ₹500 per referral"
      + "\nIn an event wherein more than 9 friends of the already registered user with FansChamp are identified to be unverified (email, mobile number, PAN card or Bank account details unverified), FansChamp holds all the rights to decline or reject the withdrawal request of the user and block the user from the Platforms of FansChamp."
      + "\nNeither the Referrer nor the Referent is permitted to use the Cash Bonus credited to their gaming accounts for joining any head-to-head contest. Additionally, the Cash Bonus amount credited to the gaming accounts of the Referrer and Referent cannot be withdrawn or transferred to any other cash balance account held by the Referrer/Referent with FansChamp or to any third party account or to any bank/payment instrument account."
      + "\nFansChamp holds all the rights to change or substitute the Cash Bonus offered under the Refer & Earn Program without any prior notice to the user. The crediting of Cash Bonus to the gaming accounts of the Referrer and Referent shall be at the sole discretion of FansChamp and shall be subject to the Referrer’s/Referent’s alignment with these Terms and conditions.",
      "\nOther Bonus"
      + "\nThe FansChamp platform, from time to time, for the interest and pleasure of its users, announces various “BONUS OFFERS” (further referred as Offers) on its platform with a limited validity period. The users availing the Offers announced on the FansChamp platform are required to abide by certain Terms & Conditions of the Offers. The Terms & Conditions for Offers announced on FansChamp platform comprises of:"
      + "\nThe Offers provided on the FansChamp platform can only be availed by the registered users of FansChamp. A person who is not registered with FansChamp platform is not authorized to take benefit of the Offers announced on FansChamp."
      + "\nThe Offers announced on the FansChamp platform are only open to the people of India as of now. The people residing out of India are not eligible for availing the Offers provided on FansChamp platform."
      + "\nThe users should abide by the fair Play Terms of FansChamp while availing the Offers provided on FansChamp viz the users should not use any fake accounts in order to avail the benefits of the Offers provided on FansChamp."
      + "\nThe Offers provided on FansChamp are all one-time offers and can only be availed once by a single user."
      + "\nIn case there are multiple Offers in a single offer, then a single user can avail all of the multiple offers provided in a single offer for once. For Instance, in a single offer, there are multiple cash bonus offering on adding different amounts, then the user is authorized to add all of the defined amounts and avail respective bonuses for once."
      + "\nThe cash bonus earned through the Offers of FansChamp shall be given to the users in the FansChamp BONUS section of their wallet."
      + "\nThe Offer should only be availed within the defined validity period in order to avail the benefits. In case a user, by mistake or due to personal negligence tries to avail the offer after it has expired then the user will not be entitled to any Offer benefits."
      + "\nThe users on the instance of receiving any bonus in the FansChamp BONUS section as a part of Offer benefit can use the bonus in accordance to the FansChamp BONUS Usage defined in the Terms & Conditions section. However, the users cannot use the FansChamp BONUS for (1) withdrawals, and; (2) transferring to any other FansChamp account held by the user."
      + "\nThe validity of bonus received in FansChamp BONUS section under any offer shall be usable for 14 days including the day of receipt, post which all such credits shall expire."
      + "\nThe CASH BONUS offered under Offers of FansChamp is different from refer & signup bonus provided under the “Refer & Earn” scheme of FansChamp."
      + "\nFansChamp shall only be responsible for offers made available on FansChamp and shall not be held liable for any offers made available by any third-party sources which may or may not be associated with FansChamp.",
      "\nGeneral Conditions"
      + "\nFansChamp reserves the right to:"
      + "\na) withhold the deposit of the Bonus Amount; and/or"
      + "\nb) forfeit any deposited Bonus Amount to a Referrer/Referent or any winnings earned by the participant by using such Bonus Amount; and/or"
      + "\nc) suspend or deactivate the gaming accounts of the user already registered with FansChamp or his/her referred friend in an event FansChamp identifies or believes the user to be breaching the Terms and Conditions defined by FansChamp."
      + "\n\nThe User is not subjected to any winnings, or bonus just because of participating in the Program."
      + "\nFansChamp is authorized to disqualify any user its sole and absolute discretion, in an event if FansChamp identifies any user to be engaged in any illegal, inadequate or unlawful conduct."
      + "\nNo correspondence, objection or complaints will be entertained by FansChamp regarding the Program, and crediting of Cash Bonus and the decision of FansChamp in this regard would be considered as final and binding."
      + "\nFansChamp can suspend or deactivate any user account in an event a user is identified to be involved in any sort of spamming on the website of FansChamp or conducting any suspicious on the Platforms of FansChamp."
      + "\nThis Program cannot be clubbed with any other contests/promotions/programs that are running simultaneously and organized or conducted by FansChamp."
      + "\nFansChamp is authorized to change, modify, substitute or withdraw the Program and/or these terms and conditions without any prior notice to the users at its sole discretion."
      + "\nThe Terms and Conditions, as applicable to the FansChamp's online fantasy sports gaming and services, will apply to and govern the Program."
      + "\nFansChamp does not make any commitment, express or implied, to respond to any feedback, suggestion and, or, queries of the participants of the Program."
      + "\nThe Terms and Conditions for Refer & Earn Program can be accessed at Refer and Earn T&C."
    ]
  }, {
    name: 'Verification',
    isExpandable: true,
    rules: [
      "In order to create, register and operate an account with FansChamp, the users need to verify personal and contact information including (i) Mobile Number, (ii) E-Mail Address, (iii) Pan Card Details; and (iv) Bank Account Details such as, Name, Bank Name, DOB, Branch, IFSC Code, State.",
      "The images provided by the individuals using the services and Features provided by FansChamp should be scanned copies of the original documents. Further, the image size of the scanned copies of original documents provided by the users for account verification should not exceed 2MB.",
      "By accepting these Terms and Conditions, the users provide their consent to provide adequate and accurate information at the time of verification. FansChamp reserves all the rights to reject the verification application of a user in an event wherein the PAN card details and Account details of the user does not match with each other.",
      "The users using the services provided by FansChamp should provide the documents demanded by FansChamp, none of the document alternatives would be accepted for the verification process. Further, the PAN card of a user creating and registering an account with FansChamp born before the year 1968 will not be accepted. In an event wherein the user creating and registering an account with FansChamp is born before the year 1968, the user will be required to provide a verification prof wherein the user should email an image holding his/her PAN card near his/her face at support@FansChamp.com",
      "The verification process of FansChamp needs 1-3 days for completion. In this regard, all the verification requests can take a time of up to 1-3 working days for completion. The users will be updated by FansChamp regarding the completion of the verification process.",
      "Any change in the information provided by the user while registration or after should only be entertained if received in writing with a valid document of change. Users are bound to provide the change in their information to FansChamp for updating their records in the system. If the user fails to provide the same, the user shall be held solely responsible for failure in receiving communication updates from FansChamp."
    ]
  }, {
    name: 'General Terms & Conditions for Pan Card and Bank Account',
    isExpandable: true,
    rules: [
      "PAN CARD"
      + "\nA pan card once uploaded by the user and verified from our end can be deleted anytime if found or suspected to be a forged document."
      + "\nIn case the documents are found or believed to be fake, the user account can be blocked under “fair play violation”."
      + "\nIn case a user is found to be a resident of Assam, Odisha, Telangana, Nagaland, Sikkim, Andhra Pradesh & Tamil Nadu after the pan card verification, his/her pan card can be deleted anytime even after the verification.",
      "\nBANK ACCOUNT"
      + "\nA bank account once uploaded by the user and verified from our end can be deleted anytime if found or suspected to be a forged document."
      + "\nIn case the documents are found or believed to be fake, the user account can be blocked under “fair play violation”."
      + "\nThe withdrawals can be withheld if the uploaded documents are found or believed to be fake amid or after the bank account verification."
      + "\nIn case a user is found to be a resident of Assam, Odisha, Telangana, Nagaland, Sikkim, Andhra Pradesh & Tamil Nadu after the bank account verification, his/her bank account can be deleted anytime even after the verification."
      + "\nAll state bank accounts have been merged to SBI, thus, make sure the IFSC entered by you should be the new IFSC."
      + "\nIt is mandatory that the bank account number on the verification image should match exactly with the account number entered in details. The “0s” mandatory to be mentioned in the details."
    ]
  }, {
    name: 'Taxation',
    isExpandable: true,
    rules: [
      "The winnings offered to the users declared as winners of the contest(s) organized by FansChamp comply with a deduction of tax (\"TDS\") under the Income Tax Act 1961. In an event, the winning amount of a user crosses the mark of ₹10,000, a 30% taxation charge will be imposed on the winning of the user. However, in case of such tax deductions, the winners will be provided TDS certificates. The individuals declared as winners by FansChamp agree to pay other applicable tax, including (but not limited to) income tax, gift tax, etc. in respect of the winnings. The winnings, or cash bonuses are subjected to various taxes.",
      "The user will not be able to withdraw or use taxable wining amount in in any of the FansChamp Contest(s) or promotional as long he/she is not providing tax filing details and the details has been verified and deemed as accurate by FansChamp team.",
      "In compliance of the section 269SU of the Income Tax Act, we insist to make payments by NEFT/RTGS/IMPS or any other E Modes."
    ]
  }, {
    name: 'Withdrawals',
    isExpandable: true,
    rules: [
      "The withdrawal request of a user is processed within 2-3 days (herein the working hours refers to bank’s working hours) excluding the date the request is made. In case, your withdrawal request is not processed within 2-3 days after the date the request is made; please check your registered email address provided by you at the time of registration for any communications from FansChamp. In an event, any FansChamp user is found to be violating the Fair Play Terms, or in general Terms & Conditions of the website then FansChamp at its sole and absolute discretion holds all the rights to take strict measures against the users including (but not limited to):",
      "In an event a user is found having more than 9 referred friends whose mobile number, email address or PAN card details are not verified, then the withdrawals requests of the user can be put on hold, or his/her user account can be blocked permanently.",
      "In an event, a user is found to have more than 9 friends whose details (mobile number, email address or PAN card details) are unverified and the user is asked to get the details (mobile number, email address, PAN card and Bank account details) of their referred friends verified by the executives of FansChamp, then the withdrawal request put on hold shall not be refunded to the FansChamp User Account of the user & shall continue to be on hold.",
      "In an event a user is found to be having more than single id registered on FansChamp or multiple ids registered under the same name then the withdrawal request of such users can either be put on hold, or their user accounts can be blocked refraining them from playing further on FansChamp.",
      "Any user if ever found to be violating the Fair Play Terms of FansChamp which can be visited on “Fair Play,” will be either blocked from playing further on FansChamp or his/her withdrawal requests will be put on hold for violating the Fair Play terms.",
      "The withdrawals of users found to be creating a fake refer chain by creating multiple ids under the same name to earn the Refer & Earn bonus will be blocked from using FansChamp services or Fun Features provided by the platform alongside putting their withdrawal requests on hold.",
      "In an event, any user is identified to be a resident of Assam, Odisha, Telangana, Nagaland, Sikkim, Andhra Pradesh & Tamil Nadu amid the FansChamp audits and had hidden this fact while registering at the platform will be blocked, and his/her withdrawal requests will be put on hold.",
      "FansChamp could put the withdrawal requests of users violating the Fair Play terms of FansChamp or can block the user account at any stage amid its audits, no matter if the withdrawal requests of the user had been accepted previously.",
      "In case the withdrawal request of a user is kept on hold, and FansChamp at its sole & absolute discretion decides to process the withdrawal request of that user after he/she agrees to abide by the Fair Play Terms of FansChamp then such withdrawals shall take 5-7 working days to process.",
      "In case, we suspect any account to be violating our fair play or verification terms already stated in the Terms & Conditions of FansChamp; we hold all the rights to verify the user at any time. FansChamp holds all the rights to video call any user at any point of time to validate & verify his/her identity. If the video call done as a part of the verification process is found to be negative, then the user account shall be blocked & his withdrawal shall be put on hold. FansChamp is free by all means to verify & validate the identity of a user at any point of time as per its will/wish.",
      "In case, a user is using a single device for creating multiple accounts on FansChamp, it should be ensured by the user that all the mandatory verification formalities including mobile, email, PAN card & Bank Account details verification has been done by the user for all the accounts created. Abiding by the said process will help such users, who create multiple accounts on a single device, in terms of smooth withdrawals on FansChamp."
    ]
  }, {
    name: 'Payment Terms',
    isExpandable: true,
    rules: [
      "In respect of any transactions entered into on the FansChamp platform, including making a payment to participate in the paid versions of Contest(s), Users agree to be bound by the following payment terms:"
      + "\nThe payment of pre-designated amount Users make to participate in the Contest(s) is inclusive of the pre-designated platform fee for access to the FansChamp Services charged by FansChamp and pre-determined participant’s contribution towards winnings."
      + "\nThe FansChamp reserves the right to charge a Contest Entry Amount, which would be specified and notified by FansChamp on the Contest page, being created on FansChamp platform, prior to a User's joining of such Contest. The Contest Entry Amount (inclusive of applicable tax thereon) will be debited from the User’s account balance."
      + "\nThe User may participate in a Contest wherein the User has to contribute a pre-specified contribution towards the Winnings of such Contest, which will be passed on to the Winner(s) of the Contest after the completion of the Contest as per the terms and conditions of such Contest.",
      "In order to ensure statutory compliance under section 269SU of the Income tax Act, we strictly encourage payments by payees to our bank accounts through electronic modes viz. NEFT, RTGS and IMPS, the details of which are reflected on our payment platform.",
      "Winnings mentioned in all our marketing communications is the cumulative winning for the particular series.",
      "Disclaimer: This game involves an element of financial risk and may be addictive. Please play responsibly and at your own risk."
    ]
  }
]);


export const FAQ = Object.freeze([
  {
    name: 'What is Fantasy Sports?',
    isExpandable: false,
    rules: [
      "It is a type of game where you will compete with friends and other users by creating virtual teams of the favorite games you are following. The teams will get points based on the point system of the game type. We are currently supporting Cricket, Formula Races (F1, F2, FE, etc.), and MotoGP (Moto1, Moto2, MotoGP, etc.).",
      "For Example, We all Love Cricket, and fantasy cricket is simple as creating your own team of 11 players from 22 playings. As your players score runs or take wickets you will be awarded points. If you outperform everyone in the league, you will be rewarded with exciting winning prizes."
    ]
  },{
    name: 'How to register?',
    isExpandable: false,
    rules: [
      "Visit FansChamp App. Register yourself by filling in your name, gender, email, phone number, password, and referral code (if available). You can register once per a valid email address and phone number.​"
    ]
  },{
    name: 'Can I update my Personal Information?',
    isExpandable: false,
    rules: [
      "Once you logged to PlazyEzy, you can navigate to the `User Details` section and select the edit. You can edit or change most of your personal information."
    ]
  },{
    name: "I'm new to fantasy sports, can I play?",
    isExpandable: false,
    rules: [
      "It's just a game, you can always play. Join our practice matches to gain valuable experience and knowledge. Also, refer to our `How to Play` section for more details."
    ]
  },{
    name: 'Till when can I change team formations?',
    isExpandable: false,
    rules: [
      "You are allowed to make changes until the scheduled start time of the match. Since competitions are started before the squad is announced, hence if your player is not playing, you are allowed to change the squad until the match starts."
    ]
  },{
    name: 'What happens if the match is abandoned or shortened?',
    isExpandable: false,
    rules: [
      "If the match is played for minmum amount of balls, the contests result will be declared based on that. In case of matches affected by rain (or D/L method) we follow the result and award points based on player performance for the shortned match."
    ]
  }, {
    name: 'What happens to the ranks if 2 or more users receive equal points?',
    isExpandable: false,
    rules: [
      "The prize money will be distributed equally between the users i.e. the prize money of positions will be added up and distributed equally among the same rank users. e.g. if 1st and 2nd position got the same point the prize amount of 1st and 2nd will be added and distributed equally among both winners, the next person will be awarded 3rd place prize money.​"
    ]
  },{
    name: `What happens if the contest doesn't have all participants?`,
    isExpandable: false,
    rules: [
      "For a contest to start, there is a minimum participant required, if it falls below the minimum participant requirement, then the entry fee will be refunded and the contest will be void.",
      "If a contest has minimum participants but not completely filled, then the contest-winning rates will be recalculated based on the actual participant to total expected participant ratio."
    ]
  },{
    name: 'What is FansChamp Wallet?',
    isExpandable: false,
    rules: [
      "The wallet is a scheme to learn further about your playing achievements. It is a one-stop for viewing all transactions which include your deposits, withdraws, and internally used in PlayEy contests."
    ]
  },{
    name: 'When does FansChamp credit the winning amount?',
    isExpandable: false,
    rules: [
      "The winning amount will be available to you immediately in the wallet to use in other contests, For withdrawal requests, it can take 3-5 working days for the amount to get credited into your account."
    ]
  },{
    name: 'How much TDS is cut?',
    isExpandable: false,
    rules: [
      "As per the law, we have to deduct Tax at source (TDS) for winnings of Rs.10,000 or more in a single match or contest. Your PAN is needed during withdrawal requests."
    ]
  },{
    name: 'What is the FansChamp referral program?',
    isExpandable: false,
    rules: [
      "You can find your referral code in the app. Use the code to invite friends and family members to join FansChamp. Your referral amount will be credited into the account in phases i.e. once your friend joins FansChamp, validates PAN and Bank details, and finally participate in the Contests."
    ]
  }
]);

export const BonusTerms = Object.freeze([
  {
    name: 'General',
    isExpandable: false,
    rules: [
      "The Refer & Earn program of FansChamp app lets the user earn cash bonus by inviting his/her friend to join FansChamp.",
      "The referrer can earn up to ₹500 cash bonus in the FansChamp BONUS section of their wallet under this promotion scheme in compliance with various T&C.",
      "You will not receive any bonus for the promotion scheme for the amount added by your referred friend during any on-going offers on FansChamp."
    ]
  },{
    name: 'When your friend verify PAN card',
    isExpandable: false,
    rules: [
      "You earn ₹50, Can view the Bonus in FansChamp BONUS section of FansChamp wallet."
    ]
  },{
    name: 'When your friend verify Bank account',
    isExpandable: false,
    rules: [
      "You earn ₹50, Can view the Bonus in FansChamp BONUS section of FansChamp wallet."
    ]
  },{
    name: 'When your friend add cash',
    isExpandable: false,
    rules: [
      "Every time your friend adds ₹50 or more you will receive 5% of the amount in the FansChamp BONUS section of your FansChamp wallet.",
      "Maximum bonus for promotion to be earned = ₹500 per referral"
    ]
  },{
    name: 'Eligibility',
    isExpandable: true,
    rules: [
      "The users that: "
      + "\na) have an account registered and a verified gaming account with FansChamp;"
      + "\nb) are eligible to participate in the pay-to-play FansChamp fantasy Sports game according to the Terms and Conditions defined by FansChamp;"
      + "\nc) have downloaded and installed the Application on their respective mobile devices, are considered as eligible for participating in the Program. FansChamp neither encourages nor permits participation in the program through a proxy.",
      "FansChamp does not permit any user to register or operate more than one gaming account with FansChamp. The participation in the Program is voluntary, and no user is allowed to participate in the Program with more than one user account.",
      "The users while participating in the Program provide their consent to abide by these Terms and Conditions of FansChamp.",      
      "The users in order to participate in the Refer & Earn Program need to download and install FansChamp's mobile application on their mobile devices. The users post downloading and installing the mobile application on their mobile devices will be provided with a unique code, which the user can distribute among his/her friends inviting them to join FansChamp by creating and registering an account with FansChamp, and downloading and installing the mobile application of FansChamp on their mobile devices. The referred friend (Referent) of the user (Referrer) already registered with FansChamp, can use the received code by directly download and install the FansChamp's mobile application on his/her device, create and register an account with FansChamp, and while regestering enter the unique code shared by the Referrer in Referral Code section.",
      "The bonus provided to the user under the Refer & Earn program of FansChamp can only be earned by the user who referred any friend in an event they abide by other terms specified in these Terms, including: (i) The referred friend of the user should not be already registered with FansChamp, (ii) both the user and its referred friend are eligible for participating in the pay-to-play Sports contest organized by FansChamp in alignment to the Terms and Conditions defined by FansChamp, (iii) the referred friend of the user should successfully create, register and verify an account with FansChamp using the unique code provided by the Referrer; and (iv) the user and his/her referred friend should agree to the license agreement of FansChamp's mobile app, and should download and install the FansChamp's mobile application in accordance to their mobile device platforms. The already registered users of FansChamp as defined in these Terms and Conditions refers to an individual who presently operates or holds an account registered with FansChamp or a user who operated or had an account with FansChamp at some point of time.",
      "FansChamp neither permits nor encourages an already registered user of FansChamp to register a new account under a new name in order to be eligible for the promotion.FansChamp is authorized to take adequate actions if a user registering with the Platform is identified to be an existing user of FansChamp.",
      "By accepting these terms and conditions, the users provide their consent to provide all the information required for the verification of a Referrer/Referent including personal information about the user (Referrer/Referent) and documents identifying the Referrer/Referent. Further, the Referrer provides his/her consent for receiving communication from FansChamp alongside permitting FansChamp to directly communicate with Referents referred by the already registered user of FansChamp. Any sort of user information collected by FansChamp amid the course of user's use of the Website shall be subject to FansChamp's Privacy Policy.",
      "Any user won't be allowed to make any withdrawals from his gaming account registered with FansChamp until the completion of the verification process of the Referrer/Referent. However, the Referent can also verify his/her personal and contact information at the time of registration of an account with FansChamp through the FansChamp's mobile application.",
      "FansChamp is authorized to disqualify any Referrer/Referent at its sole and absolute discretion, in an event if FansChamp identifies any Referrer/Referent to be engaged in any illegal, inadequate or unlawful conduct."
    ]
  }
]);

export const Privacy = Object.freeze([
  {
    name: 'General',
    isExpandable: false,
    rules: [
      "Updated on 24th April, 2022.",
      "The privacy policy and personal information safety related to the services and all Features offered by “FansChamp” are defined in this document.",
      "“FansChamp” is neither associated with nor claims any responsibility or official status with any of the official or non-official sportsperson or team. Further, the FansChamp platform is not associated or affiliated with any private or government sports leagues/tournaments in any manner unless stated by the organization itself.”",
      "FansChamp.com is the leading brand of FANSQ TECHNOLOGY(OPC) PRIVATE LIMITED (further referred as “FansChamp.com, “FansChamp” is basically/primarily a fantasy sports portal which enables its users to play online fantasy sports game.",
      "The individuals using the services or Features provided by FansChamp, or participating in any of the program(s) or contest(s) organized by FansChamp is bound to comply by these Privacy policies defined by FansChamp.",
      "FansChamp.com ensures to maintain and protect the privacy of the individuals/users using the services, Features and contest(s) offered by FansChamp.",
      "FansChamp offers a variety of Features to enhance the user experience which can be studied in details in the “About Us” section of our website. The individuals using the Features and services provided by FansChamp are required to create and register an account with FansChamp in order to avail the services offered by FansChamp."
    ]
  }, {
    name: 'Registration',
    isExpandable: true,
    rules: [
      "The individuals intending to use the services, Features and participate in the contest(s) organized by FansChamp need to complete the registration process of FansChamp.com.",
      "FansChamp registration process requires the user to submit and verify certain documents for providing personal and contact information to the FansChamp platform. The documents required for creating and registering an account with FansChamp include:"
      + "\n\ta) Name"
      + "\n\tb) PAN Card"
      + "\n\tc) Email Id"
      + "\n\td) Mobile Number"
      + "\n\te) Password"
      + "\n\tf) Date of birth"
    ]
  }, {
    name: 'User invitations',
    isExpandable: true,
    rules: [
      "The individuals using the services and Features provided by FansChamp can invite other individuals who can be either non-registered or already registered users of FansChamp using their email address or Facebook usernames for participating in any of the Features or contest (a) organized by FansChamp.",
      "Any questions or clarifications with respect to this Policy can be sent to FansChamp.com at support@FansChamp.com.  The personal or contact information provided by the users can be used by FansChamp to either contact the user or to invite the user to register with FansChamp.com and participate in the Online Fantasy Sports Games/ Features offered. In order to avail the services provided by FansChamp, the users will have to comply with the Privacy Policy and the Terms and Conditions for participation in any of the Contest(s), Program(s) and Features offered by FansChamp."
    ]
  }, {
    name: 'Use of information',
    isExpandable: true,
    rules: [
      "The user information collected and stored by FansChamp is primarily used by FansChamp to provide its services and Features to the users. The user information collected by FansChamp is associated with the contest(s), online fantasy sports games or Features that the user wishes to play or participate.",
      "Further, the collected information can also be used for the following purposes:"
      + "\na) For offering information demanded by the users regarding  Features, program(s) and contest(s) organized by FansChamp."
      + "\nb) To enhance the user-experience and Features offered to our users."
      + "\nc) Password"
      + "\nd) For informing or notifying our users about any sort of changes, modifications or"
      + "\ne) improvements to our website, services or Features that may affect our service. The user information collected and stored by FansChamp can also be shared with advertisers or third parties to get to know about the site visitors and site traffic.",
      "FansChamp reserves all the rights to disclose or share the personal information provided by the user to FansChamp with third parties in order to promote fraud protection and reduce the credit risk to minimal levels, providing services requested by the User, complying with legal process, and ensuring security related to our services.",
      "FansChamp ensures its users to notify them prior using their personal or contact information (like username, email address) for any purpose. In an event wherein any user does not want FansChamp to use or share the user data, the users will have the opportunity to withhold their consent for their use other than as listed above."
    ]
  }, {
    name: 'Collecting information/use of cookies',
    isExpandable: true,
    rules: [
      "An HTTP cookie (also called web cookie, Internet cookie, browser cookie or simply cookie), is a small piece of data sent from a website and stored in the user's web browser while the user is browsing. Every time the user loads the website, the browser sends the cookie back to the server to notify the user's previous activity.",
      "FansChamp shall use cookies and similar electronic tools in order to collect user information for enabling us to provide services and Features to the users. A unique number called User Identification (User ID) is assigned to the web browser of the user in order to assist FansChamp in understanding the individual interests of different users. Despite assigning User ID or unique numbers to different users availing the services and Features provided by FansChamp, FansChamp.com has no way to know or decide who the User is. The user cannot be identified until and unless he/she voluntarily identifies himself/herself through the registration process.",
      "A cookie can be defined as a piece of data stored on the hard drive of the user’s computer system that enables FansChamp to recognize the user, and the path user follows while visiting FansChamp.com from one page to other. The utilization of cookies assists FansChamp in customizing the website as per the interests of different users helping FansChamp to understand how different users use the services and Features provided by FansChamp alongside playing the Fantasy Games, such that the information can be used for enhancing the services, website, Features and Contest(s) provided/organized by FansChamp.",
      "The cookies used by FansChamp do not save, store or scan any personal information from the computer system of the user. The personal information stored in the form of cookies is attained through the user’s act, which can be defined as a user’s own input on a website's form. In an event wherein the third-party advertisers associated with FansChamp assign their own cookies on the computer system of a user when a user intentionally/unintentionally clicks on their ad banners, FansChamp shall not be held responsible or liable.",
      "In an event wherein an individual using the services or Features provided by FansChamp visits www.FansChamp.com, then the web servers associated with FansChamp.com will automatically collect limited information relative to the computer system of the user, including User's IP address, (User's IP address is a numerical label assigned to each device participating in a computer network that uses the Internet Protocol for communication. This number allows computers attached to the Internet know where to send data to the User -- such as the web pages viewed by the User. It is similar to that someone needs your mailing address to send you a letter). The IP address of the user’s computer system is not responsible for sharing any sort of personal information of the user. The user information collected by FansChamp is used for delivering or providing the content and services demanded by the users. In addition, this information helps FansChamp in enhancing or upgrading the services, Features or website of FansChamp in alignment with the user interests. Further, the collected or stored information helps FansChamp to analyze the site traffic and to let advertisers know the geographic locations of FansChamp.com’s visitors which ultimately signifies popularity.",
      "There are various links and redirecting URLs’ to the third-party website on FansChamp.com. The third-party websites have different privacy policies defined that are beyond the control of FansChamp and in this regard FansChamp shall not be held responsible for any loss, or inadequate usage of user information. In an event wherein any user clicks on the redirecting URLs to third party websites and leaves the FansChamp.com's servers (the User can check the URL shown in the location bar of User's browser to know where he/she is or to know the location) the usage of any information provided by the user will be governed by the privacy policy of the site’s operator which the user is visiting which might differ from the privacy policies defined by FansChamp. In an event, the user is unable to visit the privacy policy page from the homepage of the site being visited by the user, then the User may contact the site directly for more information.",
      "The user information collected and stored by FansChamp.com can be shared with the third-party advertisers associated with FansChamp to assist them to understand the preferences of the users who visit the website and also confirm the value of advertising on FansChamp.com. The user information shared by FansChamp with the third-party advertisers associated with FansChamp.com is usually in the form of aggregated statistics of traffic on various pages within FansChamp.com."
    ]
  }, {
    name: 'Information security',
    isExpandable: true,
    rules: [
      "All the user information collected and stored by FansChamp.com’s web servers is the one which the user voluntarily submits. In addition, information submitted by the user is safely and securely stored within the FansChamp.com-controlled database. The databases used by FansChamp.com for storing the user information are protected by a firewall. The access to such servers is strictly limited and is password-protected in order to ensure a safe and secure storage of the user’s personal information. However, FansChamp does not guarantee the security of the databases due to the fact that despite adopting various effective security measures, no security system is impenetrable or inaccessible. In addition to this, the user information can be obstructed amid the transfer of the information over the internet. Further, any sort of user information shared by the user amid the time of discussion over the site is public and can be easily accessed by anyone with Internet access."
    ]
  }, {
    name: 'Privacy Policy Updates',
    isExpandable: true,
    rules: [
      "FansChamp.com holds all the rights to review, modify or change the privacy policies of the site with changes or modification in the context of the internet policies. However, the usage of user information collected by FansChamp.com will stay consistent with the policy under which the information was collected, regardless of what the new policy may be."
    ]
  }, {
    name: 'Third-party app & data sharing',
    isExpandable: true,
    rules: [
      "FansChamp.com comprises of third-party advertisers that provide ads to the users at the time of their visit to FansChamp.com. These third-party advertisers might use the user information (excluding personal details like name, address, phone number etc.) for analyzing and tracking the user visits to this and other websites, in order to understand the user interests, and then provide goods, services and other advertisements on the basis of user interests. FansChamp shall not reveal, share or disclose the personal information or the identity of the user collected at the time of registration with these third-party advertisers. Several fraudulent emails, websites, blogs etc. often including the photos, logos, links, content or any other information associated with FansChamp, may or are circulating on the Internet and claim to be from or associated with FansChamp.com. Such fraudulent emails, websites, blogs etc. might often ask the users to share their personal details including (but not limited to) like login name, password etc. In addition, these fraudulent websites, emails, blogs etc. might even demand the credit or debit card details of the user by convincing the users that they have won any winnings or bonus amounts. Such emails, blogs, websites etc. are not associated with FansChamp in any manner, and FansChamp shall not be held responsible in an event any of the users reply to such emails or blogs or share any information with them. FansChamp.com strongly recommends the users not to trust, and respond to such emails, blogs, websites etc. and also not to share any credit /debit card details with them.",
      "Any doubts or comments with respect to this Privacy Policy can be sent to FansChamp.com at support@FansChamp.com."
    ]
  }, {
    name: 'Collecting user information from social media platforms',
    isExpandable: true,
    rules: [
      "We at FansChamp collect certain user information from social media platforms such as Facebook and Google in order to help the users create an account on the FansChamp platform. The information collected by FansChamp is stored in the database of FansChamp and further displayed in the Profile section of the user in order to provide an identification of the user to both the platform authorities and other users of the site. The information collected from the aforementioned social mentioned platforms include:",
      "\nId: The “Id” here refers to a unique identification number assigned to each of the users availing the services of these social media platforms. The ID collected by the FansChamp platform is used to identify the user visiting and availing the services provided on FansChamp platform.",
      "\nEmail Id: The email id of the user using Facebook and Google services is collected by FansChamp as soon as the user registers on the platform using either of the social media platforms. The email id collected by FansChamp from such social media platforms is used to be displayed in the user profile section of the individual using FansChamp services.",
      "\nFirst Name & Last Name: FansChamp also collects the First name and the last name of the individual registering on FansChamp through the aforementioned social media platforms. The first name and last name collected is also used to be displayed in the user profile section of the individual to validate the user as a verified user of FansChamp.",
      "\nDATE OF BIRTH: The FansChamp platform additionally collects the Date of Birth of the user when the individual registers on the platform using either Facebook or Google. The Date of Birth collected by FansChamp is used to identify the age of the user and testify whether the user registering with the platform is an adult & liable to use the services provided on the platform."
    ]
  }
]);