import React from "react";
import { AppBar, Toolbar, Typography, Grid, Link, Box, Container, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Security, HeadsetMicOutlined, SportsRounded, Money, Facebook, Instagram, YouTube, Twitter } from '@mui/icons-material';
import logo from './../../img/home.png';
import AppStores from "./AppStores";

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    bottom: 0,
    width: '100%'
  },
  links: {
    fontWeight: 500,
    textAlign: 'center',
    fontFamily: 'sans-serif'
  },
  boldText: {
    fontWeight: 700,
    textAlign: 'center',
    fontFamily: 'sans-serif'
  }
}));

const Footer = () => {
  const { container, links, boldText } = useStyles();

  return (
    <Box className={container} >
      <AppStores />
      <Divider variant="inset" />
      <AppBar position="static" elevation={0} component="footer" style={{ backgroundColor: '#26142a', marginTop: 10 }}>
        <Grid container spacing={0} style={{ marginTop: '20px', marginBottom: '20px' }}>
          <Grid item sm={3} xs={12}>
            <Link href="/about-us" color="inherit">
              <Box style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                <Typography variant="body2" className={links}>About FansChamp</Typography>
              </Box>
            </Link>
            <Link href="/terms-of-use" color="inherit">
              <Box style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                <Typography variant="body2" className={links}>Terms of Use</Typography>
              </Box>
            </Link>
            <Link href="/privacy-policy" color="inherit">
              <Box style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                <Typography variant="body2" className={links}>Privacy Policy</Typography>
              </Box>
            </Link>
            {/* <Link href="/fair-play-terms" color="inherit">
              <Box style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                <Typography variant="body2" className={links}>Fair Play Policy</Typography>
              </Box>
            </Link> */}
            <Link href="/refer-terms" color="inherit">
              <Box style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                <Typography variant="body2" className={links}>Refer A Friend</Typography>
              </Box>
            </Link>
          </Grid>
          <Grid item sm={3} xs={12}>
            <Link href="/how-to-play" color="inherit">
              <Box style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                <Typography variant="body2" className={links}>How to Play</Typography>
              </Box>
            </Link>
            <Link href="/point-system" color="inherit">
              <Box style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                <Typography variant="body2" className={links}>Point System</Typography>
              </Box>
            </Link>
            <Link href="/faq" color="inherit">
              <Box style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                <Typography variant="body2" className={links}>FAQ</Typography>
              </Box>
            </Link>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Box style={{ paddingTop: '5px', paddingBottom: '5px' }}>
              <Link href="/" color="inherit">
                <Container>
                  <Box component="img" className="Footer-logo"
                    sx={{ height: 40, width: 40, maxHeight: { xs: 233 }, maxWidth: { xs: 200 }, }}
                    alt="FansChamp"
                    src={logo}
                  />
                  <Typography variant="body2" style={{ fontWeight: 500, textAlign: 'center', fontFamily: 'cursive' }}> FansChamp</Typography>
                </Container>
              </Link>
            </Box>
            <Box style={{ paddingTop: '5px', paddingBottom: '5px' }}>
              <Grid container spacing={0} >
                <Grid item xs={12}>
                  <Link href="https://www.facebook.com/FansChampOfficial" color="inherit">
                    <Facebook style={{ paddingRight: '5px', color: '#FFFFFF', verticalAlign: 'middle' }} />
                  </Link>
                  <Link href="https://www.instagram.com/fanschamp/" color="inherit">
                    <Instagram style={{ paddingRight: '5px', color: '#FFFFFF', verticalAlign: 'middle' }} />
                  </Link>
                  <Link href="https://www.youtube.com/channel/UCFg0qz37SHZ6dWtYBOuYnSg" color="inherit">
                    <YouTube style={{ paddingRight: '5px', color: '#FFFFFF', verticalAlign: 'middle' }} />
                  </Link>
                  <Link href="https://twitter.com/fanschamp" color="inherit">
                    <Twitter style={{ paddingRight: '5px', color: '#FFFFFF', verticalAlign: 'middle' }} />
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </AppBar>
      <AppBar position="static" elevation={2} component="footer" style={{ backgroundColor: '#1d1c1e' }}>
        <Grid container spacing={0}>
          <Grid item sm={3} xs={12}>
            <Box style={{ paddingTop: '15px', paddingBottom: '15px' }}>
              <Security style={{ paddingRight: '5px', color: '#FFFFFF', verticalAlign: 'middle' }} />
              <Typography variant="body2" className={boldText}>Secure</Typography>
            </Box>
          </Grid>
          <Grid item sm={3} xs={12}>
            <Box style={{ paddingTop: '15px', paddingBottom: '15px' }}>
              <Money style={{ paddingRight: '5px', color: '#FFFFFF', verticalAlign: 'middle' }} />
              <Typography variant="body2" className={boldText}>Cash Bonus</Typography>
            </Box>
          </Grid>
          <Grid item sm={3} xs={12}>
            <Box style={{ paddingTop: '15px', paddingBottom: '15px' }}>
              <HeadsetMicOutlined style={{ paddingRight: '5px', color: '#FFFFFF', verticalAlign: 'middle' }} />
              <Typography variant="body2" className={boldText}>Customer Support</Typography>
            </Box>
          </Grid>
          <Grid item sm={3} xs={12}>
            <Box style={{ paddingTop: '15px', paddingBottom: '15px' }}>
              <SportsRounded style={{ paddingRight: '5px', color: '#FFFFFF', verticalAlign: 'middle' }} />
              <Typography variant="body2" className={boldText}>Fair Play</Typography>
            </Box>
          </Grid>
        </Grid>
        <Divider variant="middle" style={{ backgroundColor: '#FFFFFF', marginTop: 10, marginBottom: 10 }} />
        <Typography variant="body2" style={{ fontWeight: 400, textAlign: 'center', fontFamily: 'sans-serif' }}>Please play responsibly at your own risk. Games involves an element of financial risk.</Typography>
      </AppBar>
      <AppBar position="static" elevation={0} component="footer" color="default" style={{ backgroundColor: '#19181A' }}>
        <Toolbar style={{ justifyContent: "center" }}>
          <Typography variant="caption" style={{ color: '#ffbc1d', fontFamily: 'sans-serif' }}>{`© ${new Date().getFullYear()} FansChamp`}</Typography>
        </Toolbar>
      </AppBar>
    </Box>
  )
};
export default Footer;