import { AppBar, Toolbar, Button, IconButton, Drawer, Link, MenuItem, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Menu as MenuIcon } from '@mui/icons-material';
import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { APP_LINK, DISPLAY_APP_LINK_STYLE } from "./Settings";
import home from './../../img/home.png';

const headersData = [
  {
    label: "SIGN UP/SIGN IN",
    href: APP_LINK,
  }
];

const useStyles = makeStyles(() => ({
  header: {
    //backgroundColor: "#400CCC",
    background: "linear-gradient(to bottom, #421DF7 0%, #654FA4 100%)",
    paddingRight: "79px",
    paddingLeft: "80px",
    minHeight: '75px',
    "@media (max-width: 900px)": {
      paddingLeft: 0,
    },
  },
  logo: {
    fontFamily: "Work Sans, sans-serif",
    fontWeight: 600,
    color: "#FFFEFE",
    textAlign: "center",
  },
  menuButton: {
    fontFamily: "Open Sans, sans-serif",
    fontWeight: 700,
    size: "18px",
    marginLeft: "38px",
    backgroundColor: '#f7b819',
    display: DISPLAY_APP_LINK_STYLE
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  drawerContainer: {
    padding: "20px 30px",
  },
}));

const Header = () => {
  const { header, logo, menuButton, toolbar, drawerContainer } = useStyles();
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  const displayDesktop = () => {
    return (
      <Toolbar className={toolbar}>
        {AppName}
        <div>{getMenuButtons()}</div>
      </Toolbar>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar>
        <IconButton
          {...{
            edge: "start",
            color: "inherit",
            "aria-label": "menu",
            "aria-haspopup": "true",
            onClick: handleDrawerOpen,
          }}
        >
          <MenuIcon />
        </IconButton>

        <Drawer
          {...{
            anchor: "left",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>{getDrawerChoices()}</div>
        </Drawer>

        <div>{AppName}</div>
      </Toolbar>
    );
  };

  const getDrawerChoices = () => {
    return headersData.map(({ label, href }) => {
      return (
        <Link
          {...{
            component: RouterLink,
            to: href,
            color: "inherit",
            style: { textDecoration: "none" },
            key: label,
          }}
        >
          <MenuItem>{label}</MenuItem>
        </Link>
      );
    });
  };

  const AppName = (
    <Box >
      <Link href="/" color="inherit">
        <Box component="img" style={{flex: 1}}
          sx={{ height: 40, width: 40, maxHeight: { xs: 233}, maxWidth: { xs: 200 }, }}
          alt="FansChamp"
          src={home}
        />
      </Link>
    </Box>
  );

  const getMenuButtons = () => {
    return headersData.map(({ label, href }) => {
      return (
        <Button
          {...{
            key: label,
            color: "inherit",
            href: href,
            className: menuButton,
          }}
        >
          {label}
        </Button>
      );
    });
  };

  return (
    <header style={{ minHeight: '75px' }}>
      <AppBar className={header}>
        {displayDesktop()}
      </AppBar>
    </header>
  );
}

export default(Header);