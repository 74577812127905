let APP_SERVER = '';
let DISPLAY_APP_LINK_LOCAL = 'block';
let APP_DOWNLOAD_API = 'https://api.fanschamp.com/n1/download/appLink';
let APP_DOWNLOAD_LINK = 'https://api.fanschamp.com/n1/link';

switch (process.env.REACT_APP_ENV) {
    case 'development':
        APP_SERVER = '/devapp/';
        DISPLAY_APP_LINK_LOCAL = 'none';
        APP_DOWNLOAD_API = 'https://stage-api.fanschamp.com/n1/download/appLink';
        APP_DOWNLOAD_LINK = 'https://stage-api.fanschamp.com/n1/link';
        break;
    case 'stage':
        APP_SERVER = '/stageapp/';
        DISPLAY_APP_LINK_LOCAL = 'block';
        APP_DOWNLOAD_API = 'https://stage-api.fanschamp.com/n1/download/appLink';
        APP_DOWNLOAD_LINK = 'https://stage-api.fanschamp.com/n1/link';
        break;
    case 'prod-hide':
        APP_SERVER = '/app/';
        DISPLAY_APP_LINK_LOCAL = 'none !important';
        APP_DOWNLOAD_API = 'https://api.fanschamp.com/n1/download/appLink';
        APP_DOWNLOAD_LINK = 'https://api.fanschamp.com/n1/link';
        break;
    case 'production':
        APP_SERVER = '/app/';
        DISPLAY_APP_LINK_LOCAL = 'block';
        APP_DOWNLOAD_API = 'https://api.fanschamp.com/n1/download/appLink';
        APP_DOWNLOAD_LINK = 'https://api.fanschamp.com/n1/link';
        break;
    default:
        APP_SERVER = '/app/';
        DISPLAY_APP_LINK_LOCAL = 'none !important';
        APP_DOWNLOAD_API = 'https://stage-api.fanschamp.com/n1/download/appLink';
        APP_DOWNLOAD_LINK = 'https://stage-api.fanschamp.com/n1/link';
        break;
}

export const APP_LINK = APP_SERVER;
export const DISPLAY_APP_LINK_STYLE = DISPLAY_APP_LINK_LOCAL;
export const DOWNLOAD_API = APP_DOWNLOAD_API;
export const DOWNLOAD_LINK = APP_DOWNLOAD_LINK;