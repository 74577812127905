import { Box, Button, Divider, Grid, Stack, Typography } from '@mui/material';
import { AndroidSharp, Apple } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from 'react';

const AppStores = () => {

  const navigate = useNavigate();

  const onDwnldApple = () => {
    console.log('Apple');
    navigate('/ios');
  }

  const onDwnldAndroid = () => {
    console.log('Android');
    navigate('/android');
  };

  // const containerRef = useRef(null);
  // const ref = useRef(null);
  // const [showBtn, setShowBtn] = useState(true);

  // const isBottom = (el) => {
  //   return el.getBoundingClientRect().bottom <= window.innerHeight;
  // };

  // const trackScrolling = () => {
  //   const wrappedElement = document.getElementById('root');
  //   if (isBottom(wrappedElement)) {
  //       setShowBtn(false);
  //   } else {
  //       setShowBtn(true);
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener('scroll', trackScrolling);
  //   return () => document.removeEventListener('scroll', trackScrolling);
  // }, [trackScrolling]);


//   <Stack alignItems={'center'} sx={{
//     position: 'sticky',
//     bottom: 10,
//     display: showBtn ? 'normal' : 'none'
// }}>
// <Button
//         variant={'contained'}
//         size={'small'}
//         onClick={() => {
//             ref.current?.scrollIntoView({behavior: 'smooth'})
//         }}
//     >Scroll to down</Button>
//     </Stack>

  return (
    <Box style={{ marginTop: 20, paddingBottom: 20, }}>
      {/* <Stack alignItems={'center'} sx={{position: 'sticky', bottom: 10, display: showBtn ? 'normal' : 'none' }}> */}
      <Grid container spacing={0} justifyContent='flex-end'>
        <Grid item xs={6} >
          <Box display="flex" justifyContent="flex-end">
            <Button color="secondary" variant="contained" style={{ backgroundColor: '#f7b819', textTransform: "none", margin: 4 }} onClick={onDwnldApple}>
              <Grid container spacing={5} >
                <Grid item xs={3} style={{ alignSelf: 'center' }}>
                  <Apple fontSize='large' style={{ paddingRight: '5px', color: '#555555' }} />
                </Grid>
                <Grid item xs={9} flexDirection='row' >
                  <Typography variant='subtitle2' noWrap style={{ textAlign: 'left' }}>{'Download on the'}</Typography>
                  <Typography variant='h6' noWrap style={{ fontWeight: 'bold', textAlign: 'left' }} >{'App Store'}</Typography>
                </Grid>
              </Grid>
            </Button>
            <Divider orientation="vertical" variant="middle" flexItem />
          </Box>
        </Grid>
        {/* <Divider orientation="vertical" variant="middle" flexItem style={{ margin: 5 }} />         */}
        <Grid item xs={6} >
          <Box display="flex" justifyContent="flex-start">
            <Divider orientation="vertical" variant="middle" />
            <Button color="secondary" variant="contained" style={{ backgroundColor: '#f7b819', textTransform: "none", margin: 4 }} onClick={onDwnldAndroid}>
              <Grid container spacing={5} >
                <Grid item xs={3} style={{ alignSelf: 'center' }}>
                  <AndroidSharp fontSize='large' style={{ paddingRight: '5px', color: '#3DDC84', verticalAlign: 'middle' }} />
                </Grid>
                <Grid item xs={9} >
                  <Typography variant='body2' noWrap style={{ textAlign: 'left' }} >{'Get it for'}</Typography>
                  <Typography variant='h6' noWrap style={{ fontWeight: 'bold', textAlign: 'left' }} >{'Android User'}</Typography>
                </Grid>
              </Grid>
            </Button>
          </Box>
        </Grid>
      </Grid>
      {/* </Stack> */}
    </Box>
  );
}

export default AppStores;