import React from "react";
import { DOWNLOAD_LINK } from "./Settings";

const Redirect = ({ os }) => {
  React.useEffect(() => {
    window.location.replace(`${DOWNLOAD_LINK}/${os}`)
  }, [os])
  return <></>;
}

export default Redirect;