import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Footer from "../common/Footer";
import Header from "../common/Header";

const useStyles = makeStyles(() => ({
  container: {
    textAlign: 'center',
    minHeight: '100vh'
  },
}));

const FairPlayPolicy = () => {
  const { container } = useStyles();
  return (
    <Box className={container}>
      <Header />
      <Box>
        
      </Box>
      <Footer />
    </Box>
  );
}

export default FairPlayPolicy;